import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import * as Icon from 'react-bootstrap-icons';
import { PDFViewer, BlobProvider, pdf, Document, View, Image, Page, Text, Font, PDFDownloadLink, StyleSheet, Svg, Polygon, G } from '@react-pdf/renderer';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import pdfLogo from '../../../assets/images/pdf-logo.png';
import pdfArtwork from '../../../assets/images/artwork.png';
import PrometoMedium from '../../../assets/fonts/Prometo-Medium.ttf';
import PrometoMediumItalic from '../../../assets/fonts/Prometo-MediumItalic.ttf';
import PrometoRegular from '../../../assets/fonts/Prometo-Regular.ttf';
import PrometoLight from '../../../assets/fonts/Prometo-Light.ttf';
import PrometoLightItalic from '../../../assets/fonts/Prometo-LightItalic.ttf';

//styles
import 'react-loading-skeleton/dist/skeleton.css';

const formatBedrag = (value) => {
    var formatted = Number(value).toFixed(2);
    formatted = formatted.toString().replace(".", ",");
    return formatted;
}

// Register font
Font.register({family: 'PrometoRegular', src: PrometoRegular });
Font.register({family: 'PrometoMedium', src: PrometoMedium });
Font.register({family: 'PrometoMediumItalic', src: PrometoMediumItalic });
Font.register({family: 'PrometoLight', src: PrometoLight });
Font.register({family: 'PrometoLightItalic', src: PrometoLightItalic });

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: 35,
        fontSize: 12,
        color: '#001E4E'
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    logo: {
        width: 70,
        backgroudColor: 'yellow'
    },
    title: {
        width: 200,
    },
    schadeInfo: {
        width: 170
    },
    schadeInfoItem: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5
    },
    schadeInfoLabel: {
        fontFamily: 'PrometoLightItalic',
        fontSize: 11
    },
    schadeInfoValue: {
        fontFamily: 'PrometoMediumItalic',
        fontSize: 11
    },
    extraInfo: {
        marginTop: 50
    },
    extraInfoLabel: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        gap: 10,
        marginBottom: 10
    },
    label: {
        width: '12%',
        fontFamily: 'PrometoLightItalic',
        fontSize: 11
    },
    extraInfoValue: {
        width: '85%',
        fontFamily: 'PrometoMediumItalic',
        fontSize: 11
    },
    table: {
        marginTop: 50
    },
    tableHeaders: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        borderBottom: 1,
        borderBottomColor: '#D0D0D0',
        paddingBottom: 10
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop: 10
    },
    columnHeaderAantal: {
        width: '10%',
        fontSize: 10,
        fontFamily: 'PrometoLightItalic'
    },
    rowItemAantal: {
        width: '10%',
        fontFamily: 'PrometoRegular',
        fontSize: 11
    },
    columnHeaderOnderdeel: {
        width: '30%',
        fontSize: 10,
        fontFamily: 'PrometoLightItalic'
    },
    rowItemOnderdeel: {
        width: '30%',
        fontFamily: 'PrometoRegular',
        fontSize: 11
    },
    columnHeaderSoort: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'PrometoLightItalic'
    },
    rowItemSoort: {
        width: '15%',
        fontFamily: 'PrometoRegular',
        fontSize: 11
    },
    columnHeaderMerk: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'PrometoLightItalic'
    },
    rowItemMerk: {
        width: '15%',
        fontFamily: 'PrometoRegular',
        fontSize: 11
    },
    columnHeaderStukprijs: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'PrometoLightItalic',
        textAlign: 'right'
    },
    rowItemStukprijs: {
        width: '15%',
        fontFamily: 'PrometoRegular',
        fontSize: 11,
        textAlign: 'right'
    },
    columnHeaderTotaal: {
        width: '15%',
        fontSize: 10,
        fontFamily: 'PrometoLightItalic',
        textAlign: 'right'
    },
    rowItemTotaal: {
        width: '15%',
        fontFamily: 'PrometoRegular',
        fontSize: 11,
        textAlign: 'right'
    },
    totals: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        gap: 20,
        borderTop: 1,
        borderTopColor: '#D0D0D0',
        marginTop: 10,
        paddingTop: 20
    },
    totalsText: {
        fontFamily: 'PrometoRegular',
        fontSize: 14
    },
    artwork: {
        position: 'absolute',
        bottom: -35,
        right: -100,
        width: 600,
        height: 200
    }
});

// Create Document Component
const MyDocument = (props) => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
            <View style={styles.logo}><Image src={pdfLogo} />
</View>
            <View style={styles.title}><Text style={{ fontFamily: 'PrometoMedium', fontSize: 13 }}>Werkbon</Text></View>
            <View style={styles.schadeInfo}>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Datum:</Text><Text style={styles.schadeInfoValue}>{moment(props.gegevens.datum.value).format('DD-MM-YYYY')}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Bedrijfsnaam:</Text><Text style={styles.schadeInfoValue}>{props.gegevens.bedrijfsnaam.value}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Kenteken:</Text><Text style={styles.schadeInfoValue}>{props.gegevens.kenteken.value}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Soort voertuig:</Text><Text style={styles.schadeInfoValue}>{props.gegevens.soort_voertuig.value}</Text></View>
                <View style={styles.schadeInfoItem}><Text style={styles.schadeInfoLabel}>Monteur:</Text><Text style={styles.schadeInfoValue}>{props.gegevens.monteur.value}</Text></View>
            </View>
        </View>
        <View style={styles.extraInfo}>
            { props.gegevens.uurloon.value ? <View style={styles.extraInfoLabel}><Text style={styles.label}>Uurloon:</Text> <Text style={styles.extraInfoValue}>€ {props.gegevens.uurloon.value}</Text></View> : null }
            { props.gegevens.opmerking.value ? <View style={styles.extraInfoLabel}><Text style={styles.label}>Opmerking:</Text> <Text style={styles.extraInfoValue}>{props.gegevens.opmerking.value}</Text></View> : null }
        </View>
        <View style={styles.table}>
            <View style={styles.tableHeaders}>
                <Text style={styles.columnHeaderAantal}>Aantal</Text>
                <Text style={styles.columnHeaderOnderdeel}>Onderdeel</Text>
                <Text style={styles.columnHeaderSoort}>Soort</Text>
                <Text style={styles.columnHeaderMerk}>Merk</Text>
                <Text style={styles.columnHeaderStukprijs}>Stukprijs</Text>
                <Text style={styles.columnHeaderTotaal}>Subtotaal</Text>
            </View>
            {
                props.items.value.length > 0 && props.items.value[0].stukprijs > 0 ?
                props.items.value.map((item, index) =>  {
                    return <View key={index} style={styles.row}>
                        <Text style={styles.rowItemAantal}>{item.aantal}</Text>
                        <Text style={styles.rowItemOnderdeel}>{item.onderdeel}</Text>
                        <Text style={styles.rowItemSoort}>{item.soort}</Text>
                        <Text style={styles.rowItemMerk}>{item.merk}</Text>
                        <Text style={styles.rowItemStukprijs}>{'€ ' + formatBedrag(item.stukprijs)}</Text>
                        <Text style={styles.rowItemTotaal}>{'€ ' + formatBedrag(item.stukprijs * item.aantal)}</Text>
                    </View>
                })
                :
                null
            }
            {
                props.gegevens.uurloon.value && props.gegevens.totale_uren.value ?
                <View style={styles.row}>
                    <Text style={styles.rowItemAantal}>{props.gegevens.totale_uren.value}</Text>
                    <Text style={styles.rowItemOnderdeel}>Totale uren</Text>
                    <Text style={styles.rowItemSoort}></Text>
                    <Text style={styles.rowItemMerk}></Text>
                    <Text style={styles.rowItemStukprijs}>{'€ ' + formatBedrag(props.gegevens.uurloon.value)}</Text>
                    <Text style={styles.rowItemTotaal}>{'€ ' + formatBedrag(props.gegevens.uurloon.value * props.gegevens.totale_uren.value)}</Text>
                </View>
                :
                null
            }
        </View>
        <View style={styles.totals}>
            <Text style={styles.totalsText}>Totaal:</Text>
            <Text style={styles.totalsText}>{'€ ' + formatBedrag(props.totaalprijs)}</Text>
        </View>
        <Image style={styles.artwork} src={pdfArtwork} />
      </Page>
    </Document>
  );

const WerkbonAlgemeen = () => {

    //Set variables
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_APP_URL;
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [isLoading, setIsLoading] = useState(true);
    const [totalPrice, setTotalPrice] = useState(0);

    const [gegevens, setGegevens] = useState({
        datum: {
            value: '',
            required: true
        },
        bedrijfsnaam: {
            value: '',
            required: true
        },
        kenteken: {
            value: '',
            required: true
        },
        soort_voertuig: {
            value: '',
            required: true
        },
        monteur: {
            value: '',
            required: true,
        },
        uurloon: {
            value: '',
            required: true
        },
        totale_uren: {
            value: '',
            required: true
        },
        opmerking: {
            value: '',
            required: false
        }
    });
    const [items, setItems] = useState({
            value: [{
                onderdeel: '',
                soort: '',
                merk: '',
                aantal: '',
                stukprijs: ''
            }],
            required: false
    });

    const handleChange = (section, field, value) => {
        if (section === 'gegevens') {
            const current = {...gegevens};
            current[field].value = value;
            setGegevens(current);
        }
    }

    const handleItemsChange = (field, value, index) => {
        const current = {...items};
        current.value[index][field] = value;
        setItems(current);
        const totalPrice = items.value.reduce((total, item) => {
            const itemPrice = item.aantal * item.stukprijs;
            return total + itemPrice;
        }, 0);  
        setTotalPrice(totalPrice);
    }

    const handleItems = (type) => {
        const current = {...items};
        const obj = {
            onderdeel: '',
            soort: '',
            merk: '',
            aantal: '',
            stukprijs: ''
        }
        if (type === 'add') {
            current.value.push(obj);
        } else {
            current.value.pop();
        }
        setItems(current);
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/werkbon-algemeen" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title={'Werkbon algemeen aanmaken'} />
            
            <div className="werkbon-content">
                <div className="werkbon-formuier">
                    <div className="block">
                        <div className="content">
                            <h3>Prijsopgave</h3>
                            <div className="two-column">
                                <input type="text" placeholder="Bedrijfsnaam" className={gegevens.bedrijfsnaam.value ? 'form-field valid' : 'form-field'} value={gegevens.bedrijfsnaam.value} onChange={(e) => handleChange('gegevens', 'bedrijfsnaam', e.target.value)} />
                                <input type="text" placeholder="Kenteken" className={gegevens.kenteken.value ? 'form-field valid' : 'form-field'} value={gegevens.kenteken.value} onChange={(e) => handleChange('gegevens', 'kenteken', e.target.value)} />
                            </div>
                            <div className="two-column">
                                <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={gegevens.datum.value ? 'form-field valid' : 'form-field'} value={gegevens.datum.value} onChange={(e) => handleChange('gegevens', 'datum', e.target.value)} />
                                <input type="text" placeholder="Soort voertuig" className={gegevens.soort_voertuig.value ? 'form-field valid' : 'form-field'} value={gegevens.soort_voertuig.value} onChange={(e) => handleChange('gegevens', 'soort_voertuig', e.target.value)} />
                            </div>
                            <div className="three-column">
                                <input type="text" placeholder="Monteur" className={gegevens.monteur.value ? 'form-field valid' : 'form-field'} value={gegevens.monteur.value} onChange={(e) => handleChange('gegevens', 'monteur', e.target.value)} />
                                <input type="number" placeholder="Uurloon" className={gegevens.uurloon.value ? 'form-field valid' : 'form-field'} value={gegevens.uurloon.value} onChange={(e) => handleChange('gegevens', 'uurloon', e.target.value)} />
                                <input type="number" placeholder="Totale uren" className={gegevens.totale_uren.value ? 'form-field valid' : 'form-field'} value={gegevens.totale_uren.value} onChange={(e) => handleChange('gegevens', 'totale_uren', e.target.value)} />
                            </div>
                            <div className="werkbon-items">
                                {
                                   items.value.map((item, index) =>  {
                                        return <div key={index}>
                                            <div className="fields">
                                                <div className="two-column">
                                                    <input type="text" placeholder="Onderdeel" className={item.onderdeel ? 'form-field valid' : 'form-field'} value={item.onderdeel} onChange={(e) => handleItemsChange('onderdeel', e.target.value, index)} />
                                                    <input type="text" placeholder="Soort" className={item.soort ? 'form-field valid' : 'form-field'} value={item.soort} onChange={(e) => handleItemsChange('soort', e.target.value, index)} />
                                                </div>
                                                <div className="three-column">
                                                    <input type="text" placeholder="Merk" className={item.merk ? 'form-field valid' : 'form-field'} value={item.merk} onChange={(e) => handleItemsChange('merk', e.target.value, index)} />
                                                    <input type="number" placeholder="Aantal" className={item.aantal ? 'form-field valid' : 'form-field'} value={item.aantal} onChange={(e) => handleItemsChange('aantal', e.target.value, index)} />
                                                    <input type="number" placeholder="Stukprijs" className={item.stukprijs ? 'form-field valid' : 'form-field'} value={item.stukprijs} onChange={(e) => handleItemsChange('stukprijs', e.target.value, index)} />
                                                </div>
                                            </div>
                                        </div>
                                    }) 
                                }
                                <div className="actions">
                                    {
                                        items.value.length > 1 ?
                                        <button className="remove" onClick={() => handleItems('remove')}><Icon.DashCircleFill /></button>
                                        :
                                        null
                                    }  
                                    <button className="add" onClick={() => handleItems('add')}><Icon.PlusCircleFill /></button>
                                </div>
                            </div>
                            <textarea className="form-field" placeholder="Eventuele opmerking" value={gegevens.opmerking.value} onChange={(e) => handleChange('gegevens', 'opmerking', e.target.value)}></textarea>
                        </div>
                    </div>
                </div>
                <div className="werkbon-overzicht">

                    <div className="block">
                        <div className="content">
                            <div className="block-header">
                                <h3>Totalen</h3>
                            </div>
                            <table className="werkbon-totals">
                                <thead>
                                <tr>
                                    <th>Onderdeel</th>
                                    <th>Merk</th>
                                    <th>Aantal</th>
                                    <th style={{textAlign: 'right'}}>Stukprijs</th>
                                    <th style={{textAlign: 'right'}}>Totaalprijs</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        items.value.map((item, index) =>  {
                                            if (!Object.values(item).every(value => value === '')) {
                                                return <tr key={index}>
                                                    <td><p>{item.onderdeel}</p><span>{item.soort}</span></td>
                                                    <td>{item.merk}</td>
                                                    <td>{item.aantal}</td>
                                                    <td style={{textAlign: 'right'}}>{item.stukprijs ? '€ ' + formatBedrag(item.stukprijs) : ''}</td>
                                                    <td style={{textAlign: 'right'}}>{item.stukprijs && item.aantal ? '€ ' + (formatBedrag(item.stukprijs * item.aantal)) : ''}</td>
                                            </tr>
                                            }
                                        })
                                    }
                                    {
                                        gegevens.uurloon.value && gegevens.totale_uren.value ?
                                        <tr>
                                            <td>Totale uren</td>
                                            <td></td>
                                            <td>{gegevens.totale_uren.value}</td>
                                            <td style={{textAlign: 'right'}}>{'€ ' + formatBedrag(gegevens.uurloon.value)}</td>
                                            <td style={{textAlign: 'right'}}>{'€ ' + formatBedrag(gegevens.uurloon.value * gegevens.totale_uren.value)}</td>
                                        </tr>
                                        :
                                        null
                                    }
                                </tbody>
                            </table>
                            <div className="total-price">
                                <p>Totaal</p>
                                <p>{'€ ' + formatBedrag(totalPrice + (gegevens.uurloon.value * gegevens.totale_uren.value))}</p>
                            </div>
                            <p className="opmerking">{gegevens.opmerking.value}</p>
                            {
                                items ?
                                <PDFDownloadLink className="example-document" document={<MyDocument items={items} gegevens={gegevens} totaalprijs={totalPrice + (gegevens.uurloon.value * gegevens.totale_uren.value)} />} fileName={'werkbon.pdf'}>
                                    {({ blob, url, loading, error }) =>
                                    loading ? 'Document genereren...' : 'Download werkbon'
                                    }
                                </PDFDownloadLink>
                                :
                                null
                            }
                        </div>
                    </div>

                </div>
            </div>

        </div>
        {
            medewerker ?
            <BottomNav page="/werkbon-algemeen" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default WerkbonAlgemeen;