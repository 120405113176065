import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { Drawer, Loader } from 'rsuite';
import DataTable from 'react-data-table-component';
import { Tooltip } from 'react-tooltip';
import { exportToExcel } from 'react-json-to-excel';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import mailerSendImage from '../../../assets/images/mailersend.png';
import iconTranspas from '../../../assets/images/icon-transpas.png';

//styles
import './dieselpercentages.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

const Emails = (props) => {
    let emailHtml = '';
    props.emails.map(email => {
        emailHtml += '<p style="margin:3px 0px;">' + email + '</p>';
    })
    return(
        <div className="emails">
            {
                props.emails.length > 0 ?
                <>
                    <p id={'emails-' + props.index}>{props.emails.join(', ')}</p>
                    <Tooltip
                        anchorId={'emails-' + props.index}
                        place="top"
                        html={emailHtml}
                    />
                </>
                :
                null
            }
        </div>
    )
}
const FuelGroups = (props) => {
    let groupsHtml = '';
    props.groups.map(group => {
        groupsHtml += '<p style="margin:3px 0px;">' + group + '</p>';
    })
    return(
        <div className="groups">
            {
                props.groups.length > 0 ?
                <>
                    <p id={'groups-' + props.index}>{props.groups.join(', ')}</p>
                    <Tooltip
                        anchorId={'groups-' + props.index}
                        place="top"
                        html={groupsHtml}
                    />
                </>
                :
                null
            }
        </div>
    )
}

const DieselpercentagesVersturen = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const dieselpercentages_inzien = medewerker.dieselpercentages_inzien ? medewerker.dieselpercentages_inzien : false;
    const [debiteuren, setDebiteuren] = useState([]);
    const [loadingDebiteuren, setLoadingDebiteuren] = useState(false);
    const [selectedDebiteuren, setSelectedDebiteuren] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [fuelType, setFuelType] = useState(0);
    const [fuelPeriods, setFuelPeriods] = useState([]);
    const [fuelPeriod, setFuelPeriod] = useState(0);
    const [fuelChoices, setFuelChoices] = useState([]);
    const [fuelChoice, setFuelChoice] = useState(0);
    const [dotInfo, setDotInfo] = useState({});
    const [loadingDotInfo, setLoadingDotInfo] = useState(false);
    const [monthDot, setMonthDot] = useState(false);
    const [fuelGroup, setFuelGroup] = useState(0);
    const [fuelGroupOptions, setFuelGroupOptions] = useState([]);
    const [week, setWeek] = useState(moment().isoWeek() + 1);
    const [year, setYear] = useState(moment().format('YYYY'));
    const [dotRows, setDotRows] = useState([]);
    const [sendingDot, setSendingDot] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawerImport, setOpenDrawerImport] = useState(false);
    const [urlOptions, setUrlOptions] = useState([
        {
            label: 'OK Oliecentrale',
            value: 'https://www.ok-oliecentrale.nl/lijstprijs'
        },
        {
            label: 'BP',
            value: 'https://www.bp.com/nl_nl/netherlands/home/producten-en-services/bp-brandstoffen/landelijke-adviesprijzen.html'
        },
        {
            label: 'Evofenedex',
            value: 'https://www.evofenedex.nl/kennis/vervoer/duurzaam-vervoer/brandstof/dieselprijzen-nederland'
        },
        {
            label: 'TLN',
            value: 'https://www.tln.nl/ledenvoordeel/brandstofmonitor'
        },
        {
            label: 'United Consumers',
            value: 'https://www.unitedconsumers.com/brandstofprijzen'
        }
    ]);
    const [urlOption, setUrlOption] = useState('');
    const [debiteurenToImport, setDebiteurenToImport] = useState([]);
    const [importingDebiteuren, setImportingDebiteuren] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [validatedItems, setValidatedItems] = useState([]);
    const [allValid, setAllValid] = useState(true);
    const [sendingMails, setSendingMails] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const formatBedrag = (value) => {
        var formatted = Number(value).toFixed(4);
        formatted = formatted.toString().replace(".", ",");
        return formatted;
    }

    const columns = [
        {
            name: 'Debiteur',
            selector: row => row.naam,
            sortable: true,
            hide: 'md',
            conditionalCellStyles: [
                {
                    when: (row) => !row.exists,
                    style: {
                        backgroundColor: '#ffe3ca',
                        color: '#ff7b00'
                    },
                }
            ],
        },
        {
            name: 'Debiteurnummer',
            selector: row => row.debiteurnummer,
            sortable: true,
            hide: 'md',
            maxWidth: '120px',
            conditionalCellStyles: [
                {
                    when: (row) => !row.exists,
                    style: {
                        backgroundColor: '#ffe3ca'
                    },
                }
            ],
        },
        {
            name: 'Laatst gestuurde week',
            selector: row => row.laatst_gestuurde_week,
            sortable: true,
            maxWidth: '100px',
            conditionalCellStyles: [
                {
                    when: (row) => !row.exists,
                    style: {
                        backgroundColor: '#ffe3ca'
                    },
                }
            ],
        },
        {
            name: 'Dieselgroep',
            selector: row => row.dieselgroep,
            sortable: true,
            maxWidth: '200px',
            conditionalCellStyles: [
                {
                    when: (row) => !row.exists,
                    style: {
                        backgroundColor: '#ffe3ca'
                    },
                }
            ],
        },
        {
            name: 'Emails',
            selector: row => row.emails,
            maxWidth: '300px',
            conditionalCellStyles: [
                {
                    when: (row) => !row.exists,
                    style: {
                        backgroundColor: '#ffe3ca',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px'
                    },
                }
            ],
        }
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };

    const getFuelTypes = async () => {
        try {
            const response = await api.post('/debiteuren/brandstofsoorten');
            if (response.data) {
                setFuelTypes(response.data);
            }
        } catch (error) {
            console.log(error);
            if (!error.response) {
                return navigate('/offline');
            }
        }
    }

    const changedFuelType = async (value) => {
        setFuelType(value);
        setFuelChoice(0);
        setFuelChoices([]);
        setDebiteuren([]);
        setSelectedDebiteuren([]);
        setMonthDot(false);
        try {
            const response = await api.post('/debiteuren/brandstofperiode-opties/' + value);
            if (response.data) {
                setFuelPeriods(response.data);
            }
        } catch (error) {
            console.log(error);
            if (!error.response) {
                return navigate('/offline');
            }
        }
    }

    const getFirstFridayInPast = (fromDate) => {
        let currentDay = new Date(fromDate);
        while (currentDay.getDay() !== 5) {
          currentDay.setDate(currentDay.getDate() - 1);
        }
        return currentDay;
      }

    const getIndicesForDateRange = (data, monday, sunday, fuelgroup) => {
        const indices = [];
        for (let i = 0; i < data.length; i++) {
            const dateFrom = moment(data[i].DateFrom);
            const matchedFuelGroup = data[i].FuelGroupnr === Number(fuelgroup);
            if (dateFrom.isBetween(monday, sunday, null, '[]') && matchedFuelGroup) {
                indices.push(i);
            }
        }
        return indices;
    }

    const findDotPrices = (data, fuelGroupnr, weekNumber, yearNumber, changeValues) => {
        var current = {...dotInfo};
        const monday = moment().year(yearNumber).isoWeek(weekNumber).startOf('isoWeek');
        const sunday = monday.clone().endOf('isoWeek');
        const indices = getIndicesForDateRange(data, monday, sunday, fuelGroupnr);
        if (indices.length > 0) {
            const index = indices[0];
            const obj = {
                name: data[index] ? data[index].Description : 'Onbekend',
                peildatum: data[index] ? monthDot ? data[index].DateFrom : getFirstFridayInPast(data[index].DateFrom) : null,
                dieselprijs: data[index] ? data[index].FuelPrice : null,
                basisprijs_dot: data[index] ? data[index].BasePrice : null,
                ingang: data[index] ? data[index].DateFrom : null,
                dieselpercentage: data[index] ? data[index].Percentage : null
            };
            current = obj;
        } else {
            current = {};
        }
        if (changeValues) {
            setDotInfo(current);
        }
        return current;
    }

    const returnDebiteurenList = (data) => {
        const merged_debiteuren = data.debiteuren.map(debiteur => {
            // Find all matching transpas entries
            const matchingTranspas = data.debiteuren_transpas.filter(trans => trans.debiteurnummer === Number(debiteur.debiteurnummer));
    
            if (matchingTranspas.length > 0) {
                // Extract 'overig.description' values into the 'groepen' array
                const groepen = matchingTranspas.map(trans => trans.overig.Description);
                const arrayGroepen = matchingTranspas.map(trans => trans.overig.FuelGroup);
    
                // Merge the first matching transpas entry with the debiteur
                const mergedEntry = {...debiteur, ...matchingTranspas[0]};
    
                // Add 'groepen' array to the merged entry
                mergedEntry.groepen = groepen;
                mergedEntry.array_groepen = arrayGroepen;
    
                return mergedEntry;
            }
            return debiteur;
        });
    
        // Step 2: Add non-matching debiteuren_transpas entries
        data.debiteuren_transpas.forEach(transpas => {
            const match = data.debiteuren.find(debiteur => Number(debiteur.debiteurnummer) === transpas.debiteurnummer);
            if (!match) {
                // If there's no matching debiteur, add the transpas as a new entry with 'groepen' array
                const newEntry = {...transpas, groepen: [transpas.overig.description]};
                merged_debiteuren.push(newEntry);
            }
        });
    
        // Step 3: Transform merged_debiteuren into arrayData
        const arrayData = merged_debiteuren.map((item, index) => {
            return {
                id: item.id ? item.id : null,
                exists: item.id ? true : false,
                debiteurnummer: item.debiteurnummer,
                naam: item.overig.Name ? item.overig.Name : 'Nog toevoegen',
                url: item.url,
                laatst_gestuurde_week: item.laatst_gestuurde_week ? item.laatst_gestuurde_week : '',
                dieselgroep: <FuelGroups index={index} groups={item.groepen} />,
                group_ids: item.array_groepen,
                emails: <Emails index={index} emails={item.emails} />,
                email_array: item.emails
            };
        });
        const sortedArrayOnTranspas = arrayData.sort((a, b) => {
            if (a.id === null && b.id !== null) {
              return -1;
            } else if (a.id !== null && b.id === null) {
              return 1;
            } else {
              return 0;
            }
          });
        return sortedArrayOnTranspas;
    }

    const getDebiteurenOnPeriod = async (values) => {
        setLoadingDebiteuren(true);
        setValidatedItems([]);
        try {
            const response = await api.post('/debiteuren/dot-debiteuren/', {
                data: {
                    brandstofgroepen: values
                }
            });
            if (response.data) {
                const result = returnDebiteurenList(response.data);
                setDebiteuren(result);
                setLoadingDebiteuren(false);
            }
        } catch (error) {}
    }

    const getDotPrices = async (values, fuelgroup) => {
        try {
            const response = await api.post('/debiteuren/brandstofprijzen/', {
                data: {
                    brandstofgroepen: values
                }
            });
            if (response.data) {
                setDotRows(response.data);
                findDotPrices(response.data, fuelgroup, week, year, true);
            }
        } catch (error) {}
    }

    const changeFuelPeriod = async (value, text) => {
        setFuelPeriod(value);
        setFuelChoice(0);
        setMonthDot(false);
        if (text && text.toLowerCase().includes("maand".toLowerCase())) {
            setMonthDot(true);
        }   
        try {
            const response = await api.post('/debiteuren/brandstofkeuzes/' + value);
            if (response.data) {
                setFuelChoices(response.data);
                const values = [];
                const options = [];
                response.data.map((item) =>  {
                    values.push(item.FuelGroupnr);
                    options.push({value: item.FuelGroupnr, label: item.Description});
                })
                await getDebiteurenOnPeriod(values);
                setFuelGroupOptions(options);
                setFuelGroup(options[0].value);
                getDotPrices(values, options[0].value);
            }
        } catch (error) {}
    }

    const changeFuelChoice = async (value) => {
        setFuelChoice(value);
        setLoadingDebiteuren(true);
        setValidatedItems([]);
        if (value > 0) {
            setFuelGroup(value);
            try {
                const response = await api.post('/debiteuren/dot-debiteuren/', {
                    data: {
                        brandstofgroepen: [value]
                    }
                });
                if (response.data) {
                    const result = returnDebiteurenList(response.data);
                    setDebiteuren(result);
                    getDotPrices([value], value);
                    setLoadingDebiteuren(false);
                }
            } catch (error) {}
        } else {
            const periods = [...fuelChoices];
            const values = [];
            periods.map((item) =>  {
                values.push(item.FuelGroupnr);
            })
            getDebiteurenOnPeriod(values);
            getDotPrices(values, fuelGroup);
        }
    }

    const handleRowChange = (selectedRows) => {
        setSelectedDebiteuren(selectedRows.selectedRows);
    };

    const hasNullId = (arr) => {
        return arr.some(item => item.id === null);
    }
    const allIdsAreNull = (arr) => {
        return arr.every(item => item.id === null);
    }

    const assignImport = (items) => {
        const arrayItems = [];
        items.forEach(item => {
            const obj = {
                naam: item.naam,
                debiteurnummer: item.debiteurnummer,
                url: ''
            }
            arrayItems.push(obj);
        })
        setDebiteurenToImport(arrayItems);
    }

    const handleChangeImport = (field, index, value) => {
        const current = [...debiteurenToImport];
        current[index][field] = value;
        setDebiteurenToImport(current);
    }

    const setAllUrls = (value) => {
        setUrlOption(value);
        const current = [...debiteurenToImport];
        current.forEach(item => {
            item.url = value;
        })
        setDebiteurenToImport(current);
    }

    const importDebiteuren = async (event) => {
        event.preventDefault();
        setImportingDebiteuren(true);
        try {
            const response = await api.post('/debiteuren/import-debiteuren', {
                data: {
                    debiteuren: debiteurenToImport
                }
            });
            if (response.data) {
                notifySuccess('Debiteuren zijn succesvol geimporteerd, ververs de pagina');
                setOpenDrawerImport(false);
                setDebiteurenToImport([]);
                setImportingDebiteuren(false);
            }
        } catch (error) {
            notifyError(error.response.message);
        }
    }

    const startValidating = (items) => {
        setValidatedItems([]);
        setAllValid(true);
        setDrawerTitle('Validatie te versturen dieselpercentages week ' + items[0].week);
        setOpenDrawer(true);
        const current = [...items];
        current.forEach(item => {
            let valid = true;
            let validDot = true;

            // Check if the emails array is empty
            if (!item.contacts || item.contacts.length === 0) {
                valid = false;
            }

            // Check if any field in dotInfo is empty
            if (valid) {
                for (let dot of item.dotInfo) {
                if (typeof dot === 'object' && Object.keys(dot).length === 0) {
                    setAllValid(false);
                    validDot = false;
                    valid = false;
                    break;
                }
                for (let key in dot) {
                    if (dot[key] === '' || dot[key] === null || dot[key] === undefined) {
                        setAllValid(false);
                        validDot = false;
                        valid = false;
                        break;
                    }
                }
                if (!valid) break;
                }
            } else {
                setAllValid(false);
            }

            item.valid = valid;
            item.validDot = validDot;
        });
        setValidatedItems(current);
    }

    const sendDots = async (event) => {
        event.preventDefault();
        const payloadMailing = [];
        selectedDebiteuren.forEach(item => {
            const debiteurnummer = item.debiteurnummer;
            const index = payloadMailing.findIndex(consolidatedItem => consolidatedItem.debiteurnummer === debiteurnummer);
            item.group_ids.forEach(group_id => {
                const index = payloadMailing.findIndex(consolidatedItem => consolidatedItem.debiteurnummer === debiteurnummer);
                if (index < 0) {
					payloadMailing.push({
                        debiteur_id: item.id,
                        debiteur: item.naam,
						debiteurnummer: debiteurnummer,
                        contacts: item.email_array,
						dotInfo: [findDotPrices(dotRows, group_id, Number(week), Number(year), false)],
                        group_ids: item.group_ids,
                        laatst_gestuurde_week: item.laatst_gestuurde_week,
                        week: Number(week),
                        month: monthDot,
                        url: item.url
					});
				} else {
					payloadMailing[index].dotInfo.push(findDotPrices(dotRows, group_id, Number(week), Number(year), false));
				}
            });
        });
        startValidating(payloadMailing);
    }

    function arraysEqual(a, b) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
        
        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    }

    const sendAllDots = async () => {
        setSendingMails(true);
        setErrorMessage('');
        let finalPayload = {
            debiteuren: [],
            week: 0,
            data: [],
            total_mails: 0
        };
        for (const item of validatedItems) {
            const index = finalPayload.data.findIndex(element => arraysEqual(element.group_ids, item.group_ids));
            if (index < 0) {
                finalPayload.data.push({
                    contacts: item.contacts,
                    dotInfo: item.dotInfo,
                    group_ids: item.group_ids,
                    week: item.week,
                    month: item.month,
                    url: item.url
                });
            } else {
                finalPayload.data[index].contacts = finalPayload.data[index].contacts.concat(item.contacts);
            }
            finalPayload.debiteuren.push(item.debiteurnummer);
            finalPayload.week = item.week;
            finalPayload.total_mails += item.contacts.length;
        }
        if (finalPayload.total_mails > 500) {
            setSendingMails(false);
            setErrorMessage('Je kunt maximaal naar 500 contacten tegelijk een mail sturen. Selecteer minder debiteuren, je hebt nu ' + finalPayload.total_mails + ' contacten geselecteerd.');
            return;
        }
        try {
            const response = await api.post('/debiteuren/stuur-dot-info-bulk', {
                data: finalPayload
            });
            if (response.data) {
                notifySuccess('Alle mails zijn in wachtrij versturen gezet en worden verstuurd');
                setSendingMails(false);
                const currentDebiteuren = [...debiteuren];
                setLoadingDebiteuren(true);
                for (const item of currentDebiteuren) {
                    if (finalPayload.debiteuren.includes(item.debiteurnummer)) {
                        item.laatst_gestuurde_week = finalPayload.week;
                    }
                }
                setDebiteuren(currentDebiteuren);
                setLoadingDebiteuren(false);
            }
        } catch (error) {
            notifyError(error.response.message);
        }
    }

    const downloadExcel = () => {
        var fileName = 'export-debiteuren';
        const excelData = [];
        debiteuren.map(item => {
            const obj = {
                debiteurnummer: item.debiteurnummer,
                naam: item.naam,
                url: item.url,
                laatst_gestuurde_week: item.laatst_gestuurde_week,
                emails: item.emails.props.emails.map(subitem => subitem.replace(/ /g, '_')).join(', '),
                dieselgroepen: item.dieselgroep.props.groups.map(subitem => subitem.replace(/ /g, '_')).join(', '),
            }
            excelData.push(obj);
        })
        exportToExcel(excelData, fileName);
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (!dieselpercentages_inzien) {
            navigate('/');
        }
        getFuelTypes();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/dieselpercentages-versturen" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Dieselpercentages versturen" />

            <div className="controleren-header schadeoverzicht">
                    <div className="dropdowns">
                    {
                        fuelTypes.length > 0 ?
                            <select defaultValue={fuelType} onChange={e => changedFuelType(Number(e.target.value))}>
                                <option value={0}>Alle brandstofsoorten</option>
                                {
                                     fuelTypes.map((item, index) =>  {
                                        return <option key={index} value={item.FuelTypenr}>{item.Description}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                        fuelPeriods.length > 0 ?
                            <select defaultValue={fuelPeriod} onChange={e => {
                                const selectedText = e.target.options[e.target.selectedIndex].text;
                                changeFuelPeriod(Number(e.target.value), selectedText);
                            }}>
                                <option value={0}>Alle periodes</option>
                                {
                                     fuelPeriods.map((item, index) =>  {
                                        return <option key={index} value={item.FuelPeriodnr}>{item.Description}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                        fuelChoices.length > 0 ?
                            <select defaultValue={fuelChoice} onChange={e => changeFuelChoice(Number(e.target.value))}>
                                <option value={0}>Alle keuzes</option>
                                {
                                     fuelChoices.map((item, index) =>  {
                                        return <option key={index} value={item.FuelGroupnr}>{item.Description}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            selectedDebiteuren.length > 0 && allIdsAreNull(selectedDebiteuren) ?
                            <button className="transpas-button" onClick={() => {setOpenDrawerImport(true); assignImport(selectedDebiteuren)}}>Importeer debiteuren uit transpas</button>
                            :
                            null
                        }
                    </div>
                    <div className="schade-actions">
                    </div>
                </div>

                <div className="dot-info-contents">
                <div className="column left">
                {
                    !loadingDebiteuren && debiteuren.length > 0 ?
                    <>
                    <button onClick={() => downloadExcel()} className="button green">Exporteren naar Excel</button>
                    <DataTable
                        columns={columns}
                        data={debiteuren}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        fixedHeader
                        paginationPerPage={100}
                        selectableRows
                        onSelectedRowsChange={handleRowChange}
                    />
                    </>
                    :
                    <p>Er zijn geen debiteuren gevonden.</p>
                }
                </div>
                <div className="column right">
                    {
                        fuelGroupOptions.length > 0 ?
                            <form onSubmit={sendDots} id="send-dot" className="drawer-form" onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : null}>

                                {
                                fuelGroupOptions.length > 0 && !fuelChoice ?
                                    <div className="field" style={{marginTop:0}}>
                                        <label>Dieselgroep</label>
                                        <select className="form-field" defaultValue={fuelGroup} onChange={e => {findDotPrices(dotRows, Number(e.target.value), week, year, true); setFuelGroup(Number(e.target.value))}}>
                                            {
                                                fuelGroupOptions.map((item, index) =>  {
                                                    return <option key={index} value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    :
                                    null
                                }
                                <div className="two-column">
                                    <div className="field" style={{marginTop:0}}>
                                        <label>Weeknummer</label>
                                        <input type="number" placeholder="Weeknummer" value={week} className={'form-field'} onChange={(e) => {setWeek(e.target.value); findDotPrices(dotRows, fuelGroup, e.target.value, year, true);}} required />
                                    </div>
                                    <div className="field" style={{marginTop:0}}>
                                        <label>Jaar</label>
                                        <input type="number" placeholder="Jaar" value={year} className={'form-field'} onChange={(e) => {setYear(e.target.value); findDotPrices(dotRows, fuelGroup, week, e.target.value, true);}} required />
                                    </div>
                                </div>
                                {
                                    dotInfo ?
                                    <div className="dot-info-transpas">
                                        <h4>DOT informatie uit Transpas week {week} - {year}</h4>
                                        {
                                            loadingDotInfo ?
                                            <Loader />
                                            :
                                            <>
                                            {
                                                dotInfo ?
                                                <>
                                                    <div className="dot-row">
                                                        <p style={{textAlign:'center',color:'#FF7B00'}}>{dotInfo.name}</p>
                                                        <div className="dot-info-content">
                                                            <div className="dot-block one-half"><span>Peildatum:</span><p>{dotInfo.peildatum ? moment(dotInfo.peildatum).format('DD-MM-YYYY') : ''}</p></div>
                                                            <div className="dot-block one-half"><span>Dieselprijs:</span><p>{dotInfo.dieselprijs ? '€ ' + formatBedrag(dotInfo.dieselprijs) : ''}</p></div>
                                                            <div className="dot-block one-third"><span>Basisprijs DOT:</span><p>{dotInfo.basisprijs_dot ? '€ ' + formatBedrag(dotInfo.basisprijs_dot) : ''}</p></div>
                                                            <div className="dot-block one-third"><span>Ingang:</span><p>{dotInfo.ingang ? moment(dotInfo.ingang).format('DD-MM-YYYY') : ''}</p></div>
                                                            <div className="dot-block one-third"><span>Dieselpercentage:</span><p>{dotInfo.dieselpercentage !== null ? dotInfo.dieselpercentage + ' %' : ''}</p></div>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                <p style={{textAlign:'center'}}>Er is geen DOT informatie bekend voor week {week}</p>
                                            }
                                            
                                            </>
                                        }
                                        <img src={iconTranspas} className="logo-transpas" />
                                    </div>
                                    :
                                    null
                                }
                                {
                                    (medewerker.gebruiker.role.type === 'super_admin' || dieselpercentages_inzien) && selectedDebiteuren.length > 0 ?
                                    <>
                                    {
                                        dotInfo && dotInfo.name && fuelChoice && !hasNullId(selectedDebiteuren) ?
                                        <>
                                        { !sendingDot ? <input type="submit" value={'Verstuur DOT ' + (dotInfo && dotInfo.name && fuelChoice ? dotInfo.name : '') + ' van week ' + week} className="submit-form" style={{width:'100%'}} /> : <Loader />}
                                        </>
                                        :
                                        <>
                                        {
                                            dotInfo && dotInfo.name && !fuelChoice && !hasNullId(selectedDebiteuren) ?
                                            <>
                                            { !sendingDot ? <input type="submit" value={monthDot ? 'Verstuur alle DOT voor ' + moment(dotInfo.peildatum).format('MMMM') : 'Verstuur alle DOT van week ' + week} className="submit-form" style={{width:'100%'}} /> : <Loader />}
                                            </>
                                            :
                                            null
                                        }
                                        </>
                                    }
                                    </>
                                    :
                                    null
                                }
                            </form>
                            :
                            null
                    }
                    
                    </div>

                </div>

            <Toaster />
        </div>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Drawer.Header>
                <Drawer.Title>{drawerTitle}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
            {
                validatedItems.length > 0 ?
                <>
                <div className="validated-list">
                {
                    validatedItems.map((item, index) =>  {
                        return <div key={index} className={'validated-item ' + (item.valid ? 'valid' : 'invalid')}>
                            {item.valid ? <Icon.Check size={20} style={{marginTop: -3}} /> : <Icon.X size={20} style={{marginTop: -3}} /> }
                            <div className="validated-item-content">
                                <p>{item.debiteur}</p>
                                {!item.validDot ? <span>DOT informatie is onvolledig</span> : null}
                                {item.week === item.laatst_gestuurde_week && item.laatst_gestuurde_week ? <span>Week {item.week} is al een keer verstuurd</span> : null}
                                {item.week - item.laatst_gestuurde_week > 1 && item.laatst_gestuurde_week ? <span>Week die verstuurd wordt is niet opvolgend ({item.week} en {item.laatst_gestuurde_week})</span> : null}
                                {item.week - item.laatst_gestuurde_week < 0 && item.laatst_gestuurde_week ? <span>Week die verstuurd ({item.week}) wordt is lager dan laatst verstuurde week ({item.laatst_gestuurde_week})</span> : null}
                                {item.contacts.length === 0 ? <span>Er zijn geen ontvangers bekend</span> : null}
                            </div>
                        </div>
                    })
                }
                </div>
                {
                    allValid ?
                    <>
                    {errorMessage ? <p className="error" style={{marginTop:35}}>{errorMessage}</p> : null}
                    <button className="submit-form send-all" onClick={sendingMails ? null : sendAllDots} disabled={sendingMails}>{sendingMails ? <Loader /> : 'Alles versturen'}</button>
                    </>
                    :
                    <button className="submit-form send-all disabled" disabled>{'Er zijn fouten in de validatie'}</button>
                }
                
                </>
                :
                null
            }
            </Drawer.Body>
        </Drawer>
        <Drawer open={openDrawerImport} onClose={() => setOpenDrawerImport(false)}>
            <Drawer.Header>
                <Drawer.Title>{'Importeren debiteuren uit Transpas'}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
            <div className="field">
                <label>URL toewijzen aan items</label>
                <select defaultValue={urlOption} className={'form-field'} onChange={e => setAllUrls(e.target.value)}>
                    <option value={''}>Alle urls</option>
                    {
                            urlOptions.map((item, index) =>  {
                            return <option key={index} value={item.value}>{item.label}</option>
                            })
                    }
                </select>
            </div>
            <form id="import-debiteuren" className="drawer-form" onSubmit={importDebiteuren}>
            {
                debiteurenToImport.length > 0 ?
                <>
                {
                    debiteurenToImport.map((item, index) =>  {
                        return <div className="import-item">
                            <div className="two-column">
                                <div className="field">
                                    <label>Naam debiteur</label>
                                    <input type="text" placeholder="Naam debiteur" value={item.naam} className={'form-field'} onChange={(e) => handleChangeImport('naam', index, e.target.value)} required />
                                </div>
                                <div className="field">
                                    <label>Debiteurnummer</label>
                                    <input type="text" placeholder="Debiteurnummer" value={item.debiteurnummer} className={'form-field'} onChange={(e) => handleChangeImport('debiteurnummer', index, e.target.value)} required disabled />
                                </div>
                            </div>
                            <div className="field">
                                <label>URL</label>
                                <input type="text" placeholder="URL" value={item.url} className={'form-field'} onChange={(e) => handleChangeImport('url', index, e.target.value)} required />
                            </div>
                        </div>
                    })
                }
                <button type="submit" className="submit-form send-all">{importingDebiteuren ? <Loader /> : 'Importeer debiteuren'}</button>
                </>
                :
                null
            }
            </form>
            </Drawer.Body>
        </Drawer>
        {
            medewerker ?
            <BottomNav page="/dieselpercentages-versturen" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default DieselpercentagesVersturen;