import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, extractPlace } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Autocomplete from "react-google-autocomplete";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Lottie from "lottie-react";
import Rodal from 'rodal';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import FileUpload from '../../../components/schadeformulier/fileUpload';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import creatingDossierAnimation from "../../../assets/animations/create-dossier.json";

//styles
import './schadeformulier.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { upload } from '@testing-library/user-event/dist/upload';
import 'rodal/lib/rodal.css';


const NoOptionsMedewerkers = (props) => {
    return (
      <div className="select-no-options">
        {
            props.medewerker.gebruiker.role.type === 'super_admin' || props.medewerker.gebruiker.role.type === 'schadebeheer' ?
            <button onClick={() => {
                props.setNewMedewerkerType(props.newType); 
                props.setTypeToAdd(props.type); 
                props.setShowNewSelectOption(true);
                }}><span>{props.type} toevoegen</span><Icon.Plus />
            </button>
            :
            <span>Optie niet gevonden? Neem contact op met Edwin of Kimm via schade@vsdv.nl</span>
        }
      </div>
    );
};
const NoOptionsCharters = (props) => {
    return (
    <div className="select-no-options">
        {
            props.medewerker.gebruiker.role.type === 'super_admin' || props.medewerker.gebruiker.role.type === 'schadebeheer' ?
            <button onClick={() => {
                props.setNewMedewerkerType(props.newType);
                props.setTypeToAdd(props.type);
                props.setShowNewSelectOption(true);
            }}><span>Charter toevoegen</span><Icon.Plus />
            </button>
            :
            <span>Optie niet gevonden? Neem contact op met Edwin of Kimm via schade@vsdv.nl</span>
        }
      </div>
    );
}; 

const Schadeformulier = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const googleApiKey = process.env.GOOGLE_API_KEY;
    const [isLoading, setIsLoading] = useState(true);
    const [creatingDossier, setCreatingDossier] = useState(false);
    const [labelSchuldschadeVerkeer, setLabelSchuldschadeVerkeer] = useState('chauffeur');
    const [labelSchuldschadeLading, setLabelSchuldschadeLading] = useState('chauffeur');
    const [labelSchuldschadeWarehouse, setLabelSchuldschadeWarehouse] = useState('chauffeur');
    const [labelSchuldschadePersoonlijk, setLabelSchuldschadePersoonlijk] = useState('chauffeur');
    const [showNewSelectOption, setShowNewSelectOption] = useState(false);
    const [typeToAdd, setTypeToAdd] = useState('');
    const [newMedewerkerType, setNewMedewerkerType] = useState('');
    const [newMedewerker, setNewMedewerker] = useState('');
    const lottieAnimation = useRef();

    // Form variables 1. Soort schade
    const [soortSchade, setSoortSchade] = useState({
        verkeersschade: {
            value: false,
            required: false
        },
        ladingschade: {
            value: false,
            required: false
        },
        warehouse: {
            value: false,
            required: false
        },
        persoonlijk: {
            value: false,
            required: false
        }
    })

    //Form variables 1. Gegevens
    const [gegevens, setGegevens] = useState({
        charterschade: {
            value: false,
            required: false
        },
        datum: {
            value: '',
            required: true
        },
        chauffeur: {
            value: '',
            required: true
        },
        charter: {
            value: '',
            required: false
        },
        planner: {
            value: '',
            required: true
        },
        tegenpartij: {
            value: '',
            required: false
        },
        kenteken: {
            value: '',
            required: true
        },
        trailer: {
            value: '',
            required: false
        },
        soort_vervoer: {
            value: '',
            required: true
        },
        letselschade: {
            value: false,
            required: false
        },
        schadetype: {
            value: '',
            required: true
        },
        schadeomschrijving: {
            value: '',
            required: true
        },
        schadeoorzaak: {
            value: '',
            required: true
        },
    });
    //const [datum, setDatum] = useState('');
    const [chauffeurs, setChauffeurs] = useState([]);
    const [chauffeur, setChauffeur] = useState('');
    const [charters, setCharters] = useState([]);
    const [charter, setCharter] = useState('');
    const [planners, setPlanners] = useState([]);
    const [planner, setPlanner] = useState('');
    const [kentekens, setKentekens] = useState([]);
    const [werkmaterieel, setWerkmaterieel] = useState([]);
    const [kentekensWerkmaterieel, setKentekensWerkmaterieel] = useState([]);
    const [kentekensTranspas, setKentekensTranspas] = useState([]);
    const [kenteken, setKenteken] = useState('');
    const [trailers, setTrailers] = useState([
        {
            value: 2,
            label: '12-AAAA-C'
        }
    ]);
    const [trailer, setTrailer] = useState('');
    const [soortenVervoer, setSoortenVervoer] = useState([]);
    const [soortVervoer, setSoortVervoer] = useState('');

    //Ladingschade gegevens
    const [ladingschade, setLadingschade] = useState({
        charterschade: {
            value: false,
            required: false
        },
        datum: {
            value: '',
            required: true
        },
        charter: {
            value: '',
            required: false
        },
        chauffeur_medewerker: {
            value: '',
            required: true
        },
        planner_melder: {
            value: '',
            required: true
        },
        kenteken_werkmaterieel: {
            value: '',
            required: true
        },
        trailer: {
            value: '',
            required: false
        },
        soort_vervoer: {
            value: '',
            required: true
        },
        letselschade: {
            value: false,
            required: false
        },
        tegenpartij: {
            value: '',
            required: false
        },
        schadetype: {
            value: '',
            required: true
        },
        schadeomschrijving: {
            value: '',
            required: true
        },
        schadeoorzaak: {
            value: '',
            required: true
        },
    });

    //Warehouse gegevens
    const [warehouse, setWarehouse] = useState({
        charterschade: {
            value: false,
            required: false
        },
        datum: {
            value: '',
            required: true
        },
        soort_vervoer: {
            value: '',
            required: true
        },
        charter: {
            value: '',
            required: false
        },
        medewerker: {
            value: '',
            required: true
        },
        melder: {
            value: '',
            required: true
        },
        werkmaterieel: {
            value: '',
            required: true
        },
        tegenpartij: {
            value: '',
            required: true
        },
        letselschade: {
            value: false,
            required: false
        },
        tegenpartij: {
            value: '',
            required: false
        },
        schadetype: {
            value: '',
            required: true
        },
        schadeomschrijving: {
            value: '',
            required: true
        },
        schadeoorzaak: {
            value: '',
            required: true
        },
    });

    //Form variables Persoonlijk
    const [persoonlijk, setPersoonlijk] = useState({
        charterschade: {
            value: false,
            required: false
        },
        datum: {
            value: '',
            required: true
        },
        soort_vervoer: {
            value: '',
            required: true
        },
        charter: {
            value: '',
            required: false
        },
        chauffeur_medewerker: {
            value: '',
            required: true
        },
        planner_melder: {
            value: '',
            required: true
        },
        schadetype: {
            value: '',
            required: true
        },
        schadeomschrijving: {
            value: '',
            required: true
        },
        schadeoorzaak: {
            value: '',
            required: true
        },
    });

    //Form variables 2. Schade
    const [schade, setSchade] = useState({
        schuld_chauffeur: {
            value: false,
            required: false
        },
        verhaal_eigen: {
            value: '',
            required: true
        },
        land: {
            value: '',
            required: true
        },
        plaats: {
            value: '',
            required: true
        },
        getuigen: {
            value: [{
                naam: '',
                adres: '',
                telefoonnummer: ''
            }],
            required: false
        }
    });
    const [schadeTypes, setSchadeTypes] = useState([]);

    //Form variables 4. Documenten
    const [documenten, setDocumenten] = useState({
        schadeformulier: {
            label: 'Schadeformulier (.JPG, .PNG, .PDF)',
            value: '',
            id: 'schadeformulier',
            required: false,
            show: false,
            accept: 'image/jpeg,image/png,application/pdf,image/x-eps',
            multiple: false
        },
        voorkant_schadeformulier: {
            label: 'Voorkant schadeformulier (.JPG, .PNG, .PDF)',
            value: '',
            id: 'back',
            required: medewerker.gebruiker.role.type === 'super_admin' || medewerker.gebruiker.role.type === 'schadebeheer' ? false : true,
            show: true,
            accept: 'image/jpeg,image/png,application/pdf,image/x-eps',
            multiple: false
        },
        achterkant_schadeformulier: {
            label: 'Achterkant schadeformulier (.JPG, .PNG, .PDF)',
            value: '',
            id: 'back',
            required: false,
            show: true,
            accept: 'image/jpeg,image/png,application/pdf,image/x-eps',
            multiple: false
        },
        eigen_schade: {
            label: 'Foto’s eigen schade (.JPG, .PNG)',
            value: '',
            id: 'eigen-schade',
            required: false,
            show: true,
            accept: 'image/jpeg,image/png',
            multiple: true
        },
        schade_tegenpartij: {
            label: 'Foto’s schade tegenpartij (.JPG, .PNG)',
            value: '',
            id: 'schade-tegenpartij',
            required: false,
            show: true,
            accept: 'image/jpeg,image/png',
            multiple: true
        },
        omgeving: {
            label: 'Foto’s omgeving (.JPG, .PNG)',
            value: '',
            id: 'omgeving',
            required: false,
            show: true,
            accept: 'image/jpeg,image/png',
            multiple: true
        }
    });
    const [uploadedDocuments, setUploadedDocuments] = useState({
        schadeformulier: [],
        voorkant_schadeformulier: [],
        achterkant_schadeformulier: [],
        eigen_schade: [],
        schade_tegenpartij: [],
        omgeving: []
    });
    const [oneDocument, setOneDocument] = useState(false);

    //Legenda variables
    const [legenda, setLegenda] = useState([
        {
            name: 'Gegevens verkeersschade',
            show: false,
            valid: false,
            id: 'gegevens'
        },
        {
            name: 'Gegevens ladingschade',
            show: false,
            valid: false,
            id: 'lading'
        },
        {
            name: 'Gegevens warehouse',
            show: false,
            valid: false,
            id: 'warehouse'
        },
        {
            name: 'Gegevens persoonlijke schade',
            show: false,
            valid: false,
            id: 'persoonlijk'
        },
        {
            name: 'Schade',
            show: true,
            valid: false,
            id: 'schade'
        },
        {
            name: 'Documenten',
            show: true,
            valid: medewerker.gebruiker.role.type === 'super_admin' || medewerker.gebruiker.role.type === 'schadebeheer' ? true : false,
            id: 'documenten'
        },
    ])

    const delay = ms => new Promise(res => setTimeout(res, ms));

    const getOpties = async () => {
        setIsLoading(true);
        try {
            const response = await api.get('/schades/opties');
            if (response.data) {
                setPlanners(response.data.planners);
                setChauffeurs(response.data.chauffeurs);
                setCharters(response.data.charters);
                setSoortenVervoer(response.data.soorten_vervoer);
                setSchadeTypes(response.data.schadetypes);
                setIsLoading(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const getKentekensTranspas = async () => {
        try {
            const response = await api.get('/schades/kentekens');
            if (response.data) {
                const organizedData = Object.values(response.data).reduce((result, item) => {
                    if (!result.some((category) => category.label === item.type)) {
                      result.push({ label: item.type, options: [] });
                    }
                    result.find((category) => category.label === item.type).options.push({
                        value: item.value,
                        label: <div><span>{item.label}</span><span style={{ color: '#919191', position: 'absolute', right: 5 }}>{item.vlootnummer}</span></div>,
                        searchValue: item.value + ' ' + item.vlootnummer,
                        companyId: item.user_company_id,
                        companyName: item.company_name
                    });
                    return result;
                }, []);
                setKentekensTranspas(organizedData);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }


    //Handle form changes and check for validation
    const isFormValid = (obj) => {
        const requiredFields = Object.keys(obj).filter(key => obj[key].required);
        return requiredFields.every(key => obj[key].value !== '');
    }
    const handleChange = (section, field, value, required) => {
        const legendaItems = [...legenda];
        if (section === 'gegevens') {
            const current = {...gegevens};
            current[field].value = value ? value : '';
            // if (required !== null) {
            //     current[field].required = required;
            // }
            setGegevens(current);
            legendaItems[0].valid = isFormValid(current);
            setLegenda(legendaItems);
        }
        if (section === 'ladingschade') {
            const current = {...ladingschade};
            current[field].value = value;
            setLadingschade(current);
            legendaItems[1].valid = isFormValid(current);
            setLegenda(legendaItems);
        }
        if (section === 'warehouse') {
            const current = {...warehouse};
            current[field].value = value;
            setWarehouse(current);
            legendaItems[2].valid = isFormValid(current);
            setLegenda(legendaItems);
        }
        if (section === 'persoonlijk') {
            const current = {...persoonlijk};
            current[field].value = value;
            setPersoonlijk(current);
            legendaItems[3].valid = isFormValid(current);
            setLegenda(legendaItems);
        }
        if (section === 'schade') {
            const current = {...schade};
            current[field].value = value;
            setSchade(current);
            legendaItems[4].valid = isFormValid(current);
            setLegenda(legendaItems);
        }
        if (section === 'documenten') {
            const current = {...documenten};
            current[field].value = value ? value : '';
            setDocumenten(current);
            legendaItems[5].valid = isFormValid(current);
            setLegenda(legendaItems);
        }
    }


    //Set soort schade as first step
    const changeSoortSchade = (field) => {
        const current = {...soortSchade};
        const legendaItems = [...legenda];

        //If field is verkeersschade
        if (field === 'verkeersschade') {
            if (!soortSchade.verkeersschade.value) { 
                current[field].value = true;
                current['warehouse'].value = false;
                legendaItems[2].show = false;
                legendaItems[0].show = true;
            } else {
                current[field].value = false;
                legendaItems[0].show = false;
            }
        }
        //If field is ladingschade
        if (field === 'ladingschade') {
            if (!soortSchade.ladingschade.value) {
                current[field].value = true;
                legendaItems[1].show = true;
            } else {
                current[field].value = false;
                legendaItems[1].show = false;
            }
        }
        //If field is warehouseschade
        if (field === 'warehouse') {
            if (!soortSchade.warehouse.value) { 
                current[field].value = true;
                current['verkeersschade'].value = false;
                legendaItems[0].show = false;
                legendaItems[2].show = true;
            } else {
                current[field].value = false;
                legendaItems[2].show = false;
            }
        }
        //If field is persoonlijke schade
        if (field === 'persoonlijk') {
            if (!soortSchade.persoonlijk.value) { 
                current[field].value = true;
                legendaItems[3].show = true;
            } else {
                current[field].value = false;
                legendaItems[3].show = false;
            }
        }
        //Set new values
        setSoortSchade(current);
        setLegenda(legendaItems);
    }

    const changeLetsel = (section, field) => {
        if (section === 'gegevens') {
            const current = {...gegevens};
            if (!gegevens.letselschade.value) { 
                current[field].value = true;
            } else {
                current[field].value = false;
            }
            setGegevens(current);
        }
        if (section === 'ladingschade') {
            const current = {...ladingschade};
            if (!ladingschade.letselschade.value) { 
                current[field].value = true;
            } else {
                current[field].value = false;
            }
            setLadingschade(current);
        }
        if (section === 'warehouse') {
            const current = {...warehouse};
            if (!warehouse.letselschade.value) { 
                current[field].value = true;
            } else {
                current[field].value = false;
            }
            setWarehouse(current);
        }
    }

    const handleGetuigenChange = (field, value, index) => {
        const current = {...schade};
        current.getuigen.value[index][field] = value;
        setSchade(current);
    }

    //Change checkbox value
    const changeSchuldChauffeur = () => {
        if (schade.schuld_chauffeur.value) {
            handleChange('schade', 'schuld_chauffeur', false);
        } else {
            handleChange('schade', 'schuld_chauffeur', true);
        }
    };
    const changeCharterSchadeVerkeer = () => {
        var currentGegevens = {...gegevens};
        const legendaItems = [...legenda];
        if (currentGegevens.charterschade.value) {
            currentGegevens.charterschade.value = false;
            currentGegevens.charter.required = false;
            currentGegevens.charter.value = '';
            currentGegevens.chauffeur.required = true;
            setLabelSchuldschadeVerkeer('chauffeur');
        } else {
            currentGegevens.charterschade.value = true;
            currentGegevens.charter.required = true;
            currentGegevens.chauffeur.required = false;
            currentGegevens.chauffeur.value = '';
            setLabelSchuldschadeVerkeer('charter');
        }
        legendaItems[0].valid = isFormValid(currentGegevens);
        setLegenda(legendaItems);
        setGegevens(currentGegevens);
    };
    const changeCharterSchadeLading = () => {
        var current = {...ladingschade};
        const legendaItems = [...legenda];
        if (current.charterschade.value) {
            current.charterschade.value = false;
            current.charter.required = false;
            current.charter.value = '';
            current.chauffeur_medewerker.required = true;
            setLabelSchuldschadeLading('chauffeur');
        } else {
            current.charterschade.value = true;
            current.charter.required = true;
            current.chauffeur_medewerker.required = false;
            current.chauffeur_medewerker.value = '';
            setLabelSchuldschadeLading('charter');
        }
        legendaItems[0].valid = isFormValid(current);
        setLegenda(legendaItems);
        setLadingschade(current);
    };
    const changeCharterSchadeWarehouse = () => {
        var current = {...warehouse};
        const legendaItems = [...legenda];
        if (current.charterschade.value) {
            current.charterschade.value = false;
            current.charter.required = false;
            current.charter.value = '';
            current.medewerker.required = true;
            setLabelSchuldschadeLading('chauffeur');
        } else {
            current.charterschade.value = true;
            current.charter.required = true;
            current.medewerker.required = false;
            current.medewerker.value = '';
            setLabelSchuldschadeWarehouse('charter');
        }
        legendaItems[0].valid = isFormValid(current);
        setLegenda(legendaItems);
        setWarehouse(current);
    };
    const changeCharterSchadePersoonlijk = () => {
        var current = {...persoonlijk};
        const legendaItems = [...legenda];
        if (current.charterschade.value) {
            current.charterschade.value = false;
            current.charter.required = false;
            current.charter.value = '';
            current.chauffeur_medewerker.required = true;
            setLabelSchuldschadeLading('chauffeur');
        } else {
            current.charterschade.value = true;
            current.charter.required = true;
            current.chauffeur_medewerker.required = false;
            current.chauffeur_medewerker.value = '';
            setLabelSchuldschadePersoonlijk('charter');
        }
        legendaItems[0].valid = isFormValid(current);
        setLegenda(legendaItems);
        setPersoonlijk(current);
    };

    const handleGetuigen = (type) => {
        const current = {...schade};
        const obj = {
            naam: '',
            adres: '',
            telefoonnummer: ''
        }
        if (type === 'add') {
            current.getuigen.value.push(obj);
        } else {
            current.getuigen.value.pop();
        }
        setSchade(current);
    }

    const createNewCharter = async (event) => {
        event.preventDefault();
        const obj = {
            naam: newMedewerker
        };
        try {
            const response = await api.post('/charters', {
                data: obj
            });
            if (response.data) {
                setShowNewSelectOption(false);
                setNewMedewerker('');
                getOpties();
            }
        } catch (error) {}
    }

    const createMedewerker = async (event) => {
        event.preventDefault();
        const obj = {
            naam: newMedewerker,
            controle_naar_vervangende_controleur: false,
            uren_invullen: false,
            vast_salaris: false,
            uren_exporteren: false,
            chauffeur: newMedewerkerType === 'chauffeur' ? true : false,
            planner: newMedewerkerType === 'planner' ? true : false,
            loonperiode: 'per_maand',
            uren_inzien: false,
            kilometers_invullen: false,
            storingsdienst_invullen: false
        };
        try {
            const response = await api.post('/medewerkers', {
                data: obj
            });
            if (response.data) {
                setShowNewSelectOption(false);
                setNewMedewerker('');
                getOpties();
            }
        } catch (error) {}
    }

    const submitSchade = async () => {
        const dataObj = {
            data: {
                kenmerk: 'SVSDV',
                soorten_schade: soortSchade,
                verkeersschade: gegevens,
                ladingschade: ladingschade,
                warehouse: warehouse,
                persoonlijk: persoonlijk,
                status: { id: 1 },
                schuldschade: schade.schuld_chauffeur.value,
                verhaal_eigen: schade.verhaal_eigen.value,
                land: schade.land.value,
                plaats: {
                    naam: schade.plaats.value.naam,
                    lat: schade.plaats.value.lat.toString(),
                    lng: schade.plaats.value.lng.toString()
                },
                getuigen: schade.getuigen.value[0].naam ? schade.getuigen.value : [],
                //schadeformulier: uploadedDocuments.schadeformulier,
                //voorkant_schadeformulier: uploadedDocuments.voorkant_schadeformulier,
                //achterkant_schadeformulier: uploadedDocuments.achterkant_schadeformulier,
                fotos_eigen_schade: uploadedDocuments.eigen_schade,
                fotos_schade_tegenpartij: uploadedDocuments.schade_tegenpartij,
                fotos_omgeving: uploadedDocuments.omgeving,
                log: []
            }
        };
        try {
            const response = await api.post('/schades', dataObj);
            if (response.data) {
                if (documenten.voorkant_schadeformulier.value) {
                    await uploadDocumentFs(response.data[0], 'voorkant_schadeformulier_fs', documenten.voorkant_schadeformulier.value);
                }
                if (documenten.achterkant_schadeformulier.value) {
                    await uploadDocumentFs(response.data[0], 'achterkant_schadeformulier_fs', documenten.achterkant_schadeformulier.value);
                }
                navigate('/schades/' + response.data[0].id);
            }
        } catch (error) {}
    }

    const uploadDocument = async (files) => {
        var formData = new FormData();
        if (!files) {
            return false;
        }
        if (files.length) {
            Object.keys(files).forEach((key) => {
                formData.append("files", files[key]);
            });
        } else {
            formData.append("files", files);
        }
        try {
            const response = await api.post('/upload', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const assignDocumentFs = async (newSchade, fieldName, filename) => {
        try {
            const response = await api.put('/schades/' + newSchade.id, {
                data: {
                    [fieldName]: filename
                }
            });
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            
        }
    }

    const uploadDocumentFs = async (newSchade, fieldName, file) => {
        var formData = new FormData();
        formData.append("files", file);
        try {
            const response = await api.post('/schades/upload-document-fs/' + newSchade.kenmerk, formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                const path = response.data.filepaths[0];
                const fileName = path.match(/[^\\]+$/)[0];
                await assignDocumentFs(newSchade, fieldName, fileName);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    const addToUploaded = (type, result) => {
        var current = {...uploadedDocuments};
        if (!result) {
            return null;
        }
        if (result.length) {
            result.map((item, index) =>  {
                current[type].push(item.id);
            })
        } else {
            current[type].push(result.id);
        }
        setUploadedDocuments(current);
    }

    const uploadTheRest = async () => {
        setCreatingDossier(true);
        await delay(3500);
        const eigenSchadeUpload = uploadDocument(documenten.eigen_schade.value);
        eigenSchadeUpload.then(function(resultEigenSchade) {
            addToUploaded('eigen_schade', resultEigenSchade);
            const schadeTegenPartijUpload = uploadDocument(documenten.schade_tegenpartij.value);
            schadeTegenPartijUpload.then(function(resultSchadeTegenpartij) {
                addToUploaded('schade_tegenpartij', resultSchadeTegenpartij);
                const omgevingUpload = uploadDocument(documenten.omgeving.value);
                omgevingUpload.then(function(resultOmgeving) {
                    addToUploaded('omgeving', resultOmgeving);
                    submitSchade();
                })
            })
        })
    }

    /*
    const uploadSchadeFormulier = async () => {
        //alert('Dit onderdeel is nog in ontwikkeling');
        //return;
        setCreatingDossier(true);
        await delay(3500);
        if (oneDocument) {
            const schadeformulierUpload = uploadDocumentFs(documenten.schadeformulier.value);
            schadeformulierUpload.then(function(resultSchadeformulier) {
                addToUploaded('schadeformulier', resultSchadeformulier);
                uploadTheRest();
            })
        } else {
            const voorkantSchadeformulierUpload = uploadDocumentFs(documenten.voorkant_schadeformulier.value);
            voorkantSchadeformulierUpload.then(function(resultVoorkantSchadeFormulier) {
                addToUploaded('voorkant_schadeformulier', resultVoorkantSchadeFormulier);
                const achterkantSchadeformulierUpload = uploadDocumentFs(documenten.achterkant_schadeformulier.value);
                achterkantSchadeformulierUpload.then(function(resultAchterkantSchadeFormulier) {
                    addToUploaded('achterkant_schadeformulier', resultAchterkantSchadeFormulier);
                    uploadTheRest();
                })
            })
        }
    }
    */


    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getOpties();
        getKentekensTranspas();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/schadeformulier" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Schademeldingsformulier" />

            <div className="schadeformulier">
                <div className="formulier-content">

                    <div id="soort-schade" className="block">
                        <div className="content">
                            <div className="block-header"><h3>Wat voor soort schade wil je melden?</h3><Icon.UiChecksGrid /></div>
                            <div className="soort-schades">
                                <label className="checkbox">
                                    <input type="checkbox" checked={soortSchade.verkeersschade.value} onChange={() => changeSoortSchade('verkeersschade')} />
                                    <span className="checkbox-label">Verkeersschade</span>
                                </label>
                                <label className="checkbox">
                                    <input type="checkbox" checked={soortSchade.ladingschade.value} onChange={() => changeSoortSchade('ladingschade')} />
                                    <span className="checkbox-label">Ladingschade</span>
                                </label>
                                <label className="checkbox">
                                    <input type="checkbox" checked={soortSchade.warehouse.value} onChange={() => changeSoortSchade('warehouse')} />
                                    <span className="checkbox-label">Warehouse</span>
                                </label>
                                <label className="checkbox">
                                    <input type="checkbox" checked={soortSchade.persoonlijk.value} onChange={() => changeSoortSchade('persoonlijk')} />
                                    <span className="checkbox-label">Persoonlijk</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    {
                        soortSchade.verkeersschade.value ?
                        <div id="gegevens" className="block">
                            <div className="content">
                            <div className="block-header"><h3>Gegevens verkeersschade</h3><Icon.InfoCircle /></div>
                                <div className="two-column">
                                    <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={gegevens.datum.value ? 'form-field valid' : 'form-field'} value={gegevens.datum.value} onChange={(e) => handleChange('gegevens', 'datum', e.target.value)} disabled={isLoading} />
                                    <label className="checkbox">
                                        <input type="checkbox" checked={gegevens.charterschade.value} onChange={changeCharterSchadeVerkeer} />
                                        <span className="checkbox-label">Charterschade</span>
                                    </label>
                                </div>
                                <div className="two-column">
                                    {
                                        gegevens.charterschade.value ?
                                        <Select 
                                            options={charters} 
                                            noOptionsMessage={() => 
                                                <NoOptionsCharters 
                                                    setShowNewSelectOption={setShowNewSelectOption} 
                                                    setTypeToAdd={setTypeToAdd} 
                                                    type="Charter" 
                                                    setNewMedewerkerType={setNewMedewerkerType} 
                                                    newType="charter"
                                                    medewerker={medewerker} 
                                                />
                                            } 
                                            className={gegevens.charter.value? 'select-option valid' : 'select-option'} 
                                            placeholder="Charter" value={gegevens.charter.value} 
                                            onChange={(e) => handleChange('gegevens', 'charter', e)} 
                                            isClearable={true} 
                                            disabled={isLoading} 
                                        />
                                        :
                                        <Select options={chauffeurs} 
                                        noOptionsMessage={() => 
                                            <NoOptionsMedewerkers 
                                                setShowNewSelectOption={setShowNewSelectOption} 
                                                setTypeToAdd={setTypeToAdd} 
                                                type="Chauffeur" 
                                                setNewMedewerkerType={setNewMedewerkerType} 
                                                newType="chauffeur"
                                                medewerker={medewerker} 
                                            />
                                        }
                                        className={gegevens.chauffeur.value? 'select-option valid' : 'select-option'} 
                                        placeholder="Chauffeur" 
                                        value={gegevens.chauffeur.value} 
                                        onChange={(e) => handleChange('gegevens', 'chauffeur', e)} 
                                        isClearable={true} 
                                        disabled={isLoading} 
                                        />
                                    }
                                    <Select options={planners} 
                                        noOptionsMessage={() => 
                                            <NoOptionsMedewerkers 
                                                setShowNewSelectOption={setShowNewSelectOption} 
                                                setTypeToAdd={setTypeToAdd} 
                                                type="Planner" 
                                                setNewMedewerkerType={setNewMedewerkerType} 
                                                newType="planner"
                                                medewerker={medewerker} 
                                            />
                                        } 
                                        className={gegevens.planner.value? 'select-option valid' : 'select-option'} 
                                        placeholder="Planner" 
                                        value={gegevens.planner.value} 
                                        onChange={(e) => handleChange('gegevens', 'planner', e)} 
                                        isClearable={true} 
                                        disabled={isLoading} 
                                    />
                                </div>
                                <input type="text" name="tegenpartij" placeholder="Tegenpartij" className={gegevens.tegenpartij.value ? 'form-field valid' : 'form-field'} value={gegevens.tegenpartij.value} onChange={(e) => handleChange('gegevens', 'tegenpartij', e.target.value)} disabled={isLoading} />
                                <div className="two-column">
                                    <CreatableSelect 
                                        options={kentekensTranspas}
                                        className={gegevens.kenteken.value? 'select-option valid' : 'select-option'}
                                        placeholder="Kenteken"
                                        value={gegevens.kenteken.value}
                                        onChange={(e) => handleChange('gegevens', 'kenteken', e)}
                                        isClearable={true} disabled={isLoading}
                                        getOptionLabel={(option)=>option.label}
                                        getOptionValue={(option)=>option.searchValue}
                                    />
                                    <CreatableSelect 
                                        options={kentekensTranspas}
                                        className={gegevens.trailer.value ? 'select-option valid' : 'select-option'}
                                        placeholder="Kenteken Trailer/Aanhanger"
                                        value={gegevens.trailer.value}
                                        onChange={(e) => handleChange('gegevens', 'trailer', e)}
                                        isClearable={true} disabled={isLoading}
                                        getOptionLabel={(option)=>option.label}
                                        getOptionValue={(option)=>option.searchValue}
                                    />
                                </div>
                                <Select options={soortenVervoer} className={gegevens.soort_vervoer.value? 'select-option valid' : 'select-option'} placeholder="Soort vervoer" value={gegevens.soort_vervoer.value} onChange={(e) => handleChange('gegevens', 'soort_vervoer', e)} isClearable={true} disabled={isLoading} />
                                <label className="checkbox">
                                    <input type="checkbox" checked={gegevens.letselschade.value} onChange={() => changeLetsel('gegevens', 'letselschade')} />
                                    <span className="checkbox-label">Letsel</span>
                                </label>
                                <Select options={schadeTypes} className={gegevens.schadetype.value ? 'select-option valid' : 'select-option'} placeholder="Schadetype" value={gegevens.schadetype.value} onChange={(e) => handleChange('gegevens', 'schadetype', e)} isClearable={true} disabled={isLoading} />
                                <textarea placeholder="Schadeomschrijving" className={gegevens.schadeomschrijving.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('gegevens', 'schadeomschrijving', e.target.value)}></textarea>
                                <textarea placeholder="Schadeoorzaak" className={gegevens.schadeoorzaak.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('gegevens', 'schadeoorzaak', e.target.value)}></textarea>
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        soortSchade.ladingschade.value ?
                        <div id="lading" className="block">
                            <div className="content">
                            <div className="block-header"><h3>Gegevens ladingschade</h3><Icon.InfoCircle /></div>
                                <div className="two-column">
                                    <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={ladingschade.datum.value ? 'form-field valid' : 'form-field'} value={ladingschade.datum.value} onChange={(e) => handleChange('ladingschade', 'datum', e.target.value)} disabled={isLoading} />
                                    <label className="checkbox">
                                        <input type="checkbox" checked={ladingschade.charterschade.value} onChange={changeCharterSchadeLading} />
                                        <span className="checkbox-label">Charterschade</span>
                                    </label>
                                </div>
                                <div className="two-column">
                                {
                                    ladingschade.charterschade.value ?
                                        <Select 
                                            options={charters} 
                                            noOptionsMessage={() => 
                                                <NoOptionsCharters 
                                                    setShowNewSelectOption={setShowNewSelectOption} 
                                                    setTypeToAdd={setTypeToAdd} 
                                                    type="Charter" 
                                                    setNewMedewerkerType={setNewMedewerkerType} 
                                                    newType="charter"
                                                    medewerker={medewerker} 
                                                />
                                            } 
                                            className={ladingschade.charter.value? 'select-option valid' : 'select-option'} 
                                            placeholder="Charter" value={ladingschade.charter.value} 
                                            onChange={(e) => handleChange('ladingschade', 'charter', e)} 
                                            isClearable={true} 
                                            disabled={isLoading} 
                                        />
                                        :
                                        <Select options={chauffeurs} 
                                            noOptionsMessage={() => 
                                                <NoOptionsMedewerkers 
                                                    setShowNewSelectOption={setShowNewSelectOption} 
                                                    setTypeToAdd={setTypeToAdd} 
                                                    type="Chauffeur" 
                                                    setNewMedewerkerType={setNewMedewerkerType} 
                                                    newType="chauffeur"
                                                    medewerker={medewerker} 
                                                />
                                            }
                                            className={ladingschade.chauffeur_medewerker.value? 'select-option valid' : 'select-option'} 
                                            placeholder="Chauffeur/medewerker" 
                                            value={ladingschade.chauffeur_medewerker.value} 
                                            onChange={(e) => handleChange('ladingschade', 'chauffeur_medewerker', e)} 
                                            isClearable={true} 
                                            disabled={isLoading} 
                                        />
                                    }
                                    <Select options={planners} noOptionsMessage={() => <NoOptionsMedewerkers setShowNewSelectOption={setShowNewSelectOption} setTypeToAdd={setTypeToAdd} type="Planner" setNewMedewerkerType={setNewMedewerkerType} newType="planner" medewerker={medewerker} />} className={ladingschade.planner_melder.value ? 'select-option valid' : 'select-option'} placeholder="Planner/melder" value={ladingschade.planner_melder.value} onChange={(e) => handleChange('ladingschade', 'planner_melder', e)} isClearable={true} disabled={isLoading} />
                                </div>
                                <div className="two-column">
                                    <CreatableSelect 
                                        options={kentekensTranspas}
                                        className={ladingschade.kenteken_werkmaterieel.value ? 'select-option valid' : 'select-option'}
                                        placeholder="Kenteken/werkmaterieel"
                                        value={ladingschade.kenteken_werkmaterieel.value}
                                        onChange={(e) => handleChange('ladingschade', 'kenteken_werkmaterieel', e)}
                                        isClearable={true} disabled={isLoading}
                                        getOptionLabel={(option)=>option.label}
                                        getOptionValue={(option)=>option.searchValue}
                                    />
                                    <CreatableSelect 
                                        options={kentekensTranspas}
                                        className={ladingschade.trailer.value ? 'select-option valid' : 'select-option'}
                                        placeholder="Kenteken/werkmaterieel"
                                        value={ladingschade.trailer.value}
                                        onChange={(e) => handleChange('ladingschade', 'trailer', e)}
                                        isClearable={true} disabled={isLoading}
                                        getOptionLabel={(option)=>option.label}
                                        getOptionValue={(option)=>option.searchValue}
                                    />
                                </div>
                                <Select options={soortenVervoer} className={ladingschade.soort_vervoer.value? 'select-option valid' : 'select-option'} placeholder="Soort vervoer" value={ladingschade.soort_vervoer.value} onChange={(e) => handleChange('ladingschade', 'soort_vervoer', e)} isClearable={true} disabled={isLoading} />
                                <div className="two-column">
                                <input type="text" name="tegenpartij" placeholder="Tegenpartij" className={ladingschade.tegenpartij.value ? 'form-field valid' : 'form-field'} value={ladingschade.tegenpartij.value} onChange={(e) => handleChange('ladingschade', 'tegenpartij', e.target.value)} disabled={isLoading} />
                                <label className="checkbox">
                                    <input type="checkbox" checked={ladingschade.letselschade.value} onChange={() => changeLetsel('ladingschade', 'letselschade')} />
                                    <span className="checkbox-label">Letsel</span>
                                </label>
                                </div>
                                <Select options={schadeTypes} className={ladingschade.schadetype.value ? 'select-option valid' : 'select-option'} placeholder="Schadetype" value={ladingschade.schadetype.value} onChange={(e) => handleChange('ladingschade', 'schadetype', e)} isClearable={true} disabled={isLoading} />
                                <textarea placeholder="Schadeomschrijving" className={ladingschade.schadeomschrijving.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('ladingschade', 'schadeomschrijving', e.target.value)}></textarea>
                                <textarea placeholder="Schadeoorzaak" className={ladingschade.schadeoorzaak.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('ladingschade', 'schadeoorzaak', e.target.value)}></textarea>
                            </div>
                        </div>
                        :
                        null
                    }


                    {
                        soortSchade.warehouse.value ?
                        <div id="warehouse" className="block">
                            <div className="content">
                            <div className="block-header"><h3>Gegevens warehouse</h3><Icon.InfoCircle /></div>
                                <div className="two-column">
                                    <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={warehouse.datum.value ? 'form-field valid' : 'form-field'} value={warehouse.datum.value} onChange={(e) => handleChange('warehouse', 'datum', e.target.value)} disabled={isLoading} />
                                    <label className="checkbox">
                                        <input type="checkbox" checked={warehouse.charterschade.value} onChange={changeCharterSchadeWarehouse} />
                                        <span className="checkbox-label">Charterschade</span>
                                    </label>
                                </div>
                                <Select options={soortenVervoer} className={warehouse.soort_vervoer.value? 'select-option valid' : 'select-option'} placeholder="Soort vervoer" value={warehouse.soort_vervoer.value} onChange={(e) => handleChange('warehouse', 'soort_vervoer', e)} isClearable={true} disabled={isLoading} />
                                <div className="two-column">
                                    {
                                    warehouse.charterschade.value ?
                                        <Select 
                                            options={charters} 
                                            noOptionsMessage={() => 
                                                <NoOptionsCharters 
                                                    setShowNewSelectOption={setShowNewSelectOption} 
                                                    setTypeToAdd={setTypeToAdd} 
                                                    type="Charter" 
                                                    setNewMedewerkerType={setNewMedewerkerType} 
                                                    newType="charter"
                                                    medewerker={medewerker} 
                                                />
                                            } 
                                            className={warehouse.charter.value? 'select-option valid' : 'select-option'} 
                                            placeholder="Charter" value={warehouse.charter.value} 
                                            onChange={(e) => handleChange('warehouse', 'charter', e)} 
                                            isClearable={true} 
                                            disabled={isLoading} 
                                        />
                                        :
                                        <Select options={chauffeurs} 
                                            noOptionsMessage={() => 
                                                <NoOptionsMedewerkers 
                                                    setShowNewSelectOption={setShowNewSelectOption} 
                                                    setTypeToAdd={setTypeToAdd} 
                                                    type="Chauffeur" 
                                                    setNewMedewerkerType={setNewMedewerkerType} 
                                                    newType="chauffeur"
                                                    medewerker={medewerker} 
                                                />
                                            }
                                            className={warehouse.medewerker.value? 'select-option valid' : 'select-option'} 
                                            placeholder="Medewerker" 
                                            value={warehouse.medewerker.value} 
                                            onChange={(e) => handleChange('warehouse', 'medewerker', e)} 
                                            isClearable={true} 
                                            disabled={isLoading} 
                                        />
                                    }
                                    <Select options={planners} noOptionsMessage={() => <NoOptionsMedewerkers setShowNewSelectOption={setShowNewSelectOption} setTypeToAdd={setTypeToAdd} type="Medewerker" setNewMedewerkerType={setNewMedewerkerType} newType="planner" medewerker={medewerker} />} className={warehouse.melder.value? 'select-option valid' : 'select-option'} placeholder="Melder" value={warehouse.melder.value} onChange={(e) => handleChange('warehouse', 'melder', e)} isClearable={true} disabled={isLoading} />
                                </div>
                                <div className="two-column">
                                    <Select options={kentekensTranspas} className={warehouse.werkmaterieel.value? 'select-option valid' : 'select-option'} placeholder="Werkmaterieel/chassisnummer" value={warehouse.werkmaterieel.value} onChange={(e) => handleChange('warehouse', 'werkmaterieel', e)} isClearable={true} disabled={isLoading} />
                                    <input type="text" name="tegenpartij" placeholder="Tegenpartij" className={warehouse.tegenpartij.value ? 'form-field valid' : 'form-field'} value={warehouse.tegenpartij.value} onChange={(e) => handleChange('warehouse', 'tegenpartij', e.target.value)} disabled={isLoading} />
                                </div>
                                <label className="checkbox">
                                    <input type="checkbox" checked={warehouse.letselschade.value} onChange={() => changeLetsel('warehouse', 'letselschade')} />
                                    <span className="checkbox-label">Letsel</span>
                                </label>
                                <Select options={schadeTypes} className={warehouse.schadetype.value ? 'select-option valid' : 'select-option'} placeholder="Schadetype" value={warehouse.schadetype.value} onChange={(e) => handleChange('warehouse', 'schadetype', e)} isClearable={true} disabled={isLoading} />
                                <textarea placeholder="Schadeomschrijving" className={warehouse.schadeomschrijving.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('warehouse', 'schadeomschrijving', e.target.value)}></textarea>
                                <textarea placeholder="Schadeoorzaak" className={warehouse.schadeoorzaak.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('warehouse', 'schadeoorzaak', e.target.value)}></textarea>
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        soortSchade.persoonlijk.value ?
                        <div id="persoonlijk" className="block">
                            <div className="content">
                            <div className="block-header"><h3>Persoonlijk</h3><Icon.PersonFillExclamation /></div>
                            <div className="two-column">
                                <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" className={persoonlijk.datum.value ? 'form-field valid' : 'form-field'} value={persoonlijk.datum.value} onChange={(e) => handleChange('persoonlijk', 'datum', e.target.value)} disabled={isLoading} />
                                <label className="checkbox">
                                    <input type="checkbox" checked={persoonlijk.charterschade.value} onChange={changeCharterSchadePersoonlijk} />
                                    <span className="checkbox-label">Charterschade</span>
                                </label>
                            </div>
                            <Select options={soortenVervoer} className={persoonlijk.soort_vervoer.value? 'select-option valid' : 'select-option'} placeholder="Soort vervoer" value={persoonlijk.soort_vervoer.value} onChange={(e) => handleChange('persoonlijk', 'soort_vervoer', e)} isClearable={true} disabled={isLoading} />
                            <div className="two-column">
                            {
                                persoonlijk.charterschade.value ?
                                    <Select 
                                        options={charters} 
                                        noOptionsMessage={() => 
                                            <NoOptionsCharters 
                                                setShowNewSelectOption={setShowNewSelectOption} 
                                                setTypeToAdd={setTypeToAdd} 
                                                type="Charter" 
                                                setNewMedewerkerType={setNewMedewerkerType} 
                                                newType="charter"
                                                medewerker={medewerker} 
                                            />
                                        } 
                                        className={persoonlijk.charter.value? 'select-option valid' : 'select-option'} 
                                        placeholder="Charter" value={persoonlijk.charter.value} 
                                        onChange={(e) => handleChange('persoonlijk', 'charter', e)} 
                                        isClearable={true} 
                                        disabled={isLoading} 
                                    />
                                    :
                                    <Select options={chauffeurs} 
                                        noOptionsMessage={() => 
                                            <NoOptionsMedewerkers 
                                                setShowNewSelectOption={setShowNewSelectOption} 
                                                setTypeToAdd={setTypeToAdd} 
                                                type="Chauffeur" 
                                                setNewMedewerkerType={setNewMedewerkerType} 
                                                newType="chauffeur"
                                                medewerker={medewerker} 
                                            />
                                        }
                                        className={persoonlijk.chauffeur_medewerker.value? 'select-option valid' : 'select-option'} 
                                        placeholder="Chauffeur/medewerker" 
                                        value={persoonlijk.chauffeur_medewerker.value} 
                                        onChange={(e) => handleChange('persoonlijk', 'chauffeur_medewerker', e)} 
                                        isClearable={true} 
                                        disabled={isLoading} 
                                    />
                                }
                                <Select options={planners} noOptionsMessage={() => <NoOptionsMedewerkers setShowNewSelectOption={setShowNewSelectOption} setTypeToAdd={setTypeToAdd} type="Planner" setNewMedewerkerType={setNewMedewerkerType} newType="planner" medewerker={medewerker} />} className={persoonlijk.planner_melder.value? 'select-option valid' : 'select-option'} placeholder="Planner" value={persoonlijk.planner_melder.value} onChange={(e) => handleChange('persoonlijk', 'planner_melder', e)} isClearable={true} disabled={isLoading} />
                            </div>
                            <Select options={schadeTypes} className={persoonlijk.schadetype.value ? 'select-option valid' : 'select-option'} placeholder="Schadetype" value={persoonlijk.schadetype.value} onChange={(e) => handleChange('persoonlijk', 'schadetype', e)} isClearable={true} disabled={isLoading} />
                            <textarea placeholder="Schadeomschrijving" className={persoonlijk.schadeomschrijving.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('persoonlijk', 'schadeomschrijving', e.target.value)}></textarea>
                            <textarea placeholder="Schadeoorzaak" className={persoonlijk.schadeoorzaak.value ? 'form-field valid' : 'form-field'} rows="5" onChange={(e) => handleChange('persoonlijk', 'schadeoorzaak', e.target.value)}></textarea>
                            </div>
                        </div>
                        :
                        null
                    }
                    
                    <div id="schade" className="block">
                        <div className="content">
                        <div className="block-header"><h3>Schade</h3><Icon.ClipboardPulse />
                        </div>
                            <div className="two-column">
                            <label className="checkbox">
                                <input type="checkbox" checked={schade.schuld_chauffeur.value} onChange={changeSchuldChauffeur} />
                                <span className="checkbox-label">Schuld van VSDV {labelSchuldschadeVerkeer} / medewerker</span>
                            </label>
                            <div className="switch">
                                <label className={'item ' + (schade.verhaal_eigen.value === 'verhaal' ? 'current' : '')} onClick={() => handleChange('schade', 'verhaal_eigen', 'verhaal')}>Verhaalschade</label>
                                <label className={'item ' + (schade.verhaal_eigen.value === 'eigen' ? 'current' : '')} onClick={() => handleChange('schade', 'verhaal_eigen', 'eigen')}>Eigen schade</label>
                            </div>
                            </div>
                            <div className="two-column">
                            <Autocomplete
                                id="land"
                                apiKey={'AIzaSyASACpMzJ3uqvqiUkW9flmlJWr4yNQnLqk'}
                                options={{
                                    types: ["country"],
                                }}
                                language="nl"
                                onPlaceSelected={(place) => {
                                    handleChange('schade', 'land', place.address_components[0].short_name);
                                }}
                                className={schade.land.value ? 'form-field valid' : 'form-field'}
                                placeholder="Land"
                                onChange={() => {
                                    handleChange('schade', 'land', '');
                                    handleChange('schade', 'plaats', '');
                                    document.getElementById('plaats').value = '';
                                }}
                            />
                            <Autocomplete
                                id="plaats"
                                apiKey={'AIzaSyASACpMzJ3uqvqiUkW9flmlJWr4yNQnLqk'}
                                options={{
                                    //types: ["(regions)"],
                                    types: ["geocode"],
                                    componentRestrictions: { country: schade.land.value },
                                }}
                                language="nl"
                                onPlaceSelected={(place) => {
                                    const latlng = {
                                        //naam: place.address_components[0].long_name,
                                        naam: extractPlace(place.address_components),
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng()
                                    }
                                    handleChange('schade', 'plaats', latlng)
                                }}
                                className={schade.plaats.value ? 'form-field valid' : 'form-field'}
                                placeholder={schade.land.value ? 'Plaats' : 'Plaats (selecteer eerst een land)'}
                                onChange={() => handleChange('schade', 'plaats', '')}
                                disabled={schade.land.value ? false : true}
                            />
                            </div>
                            <h4>Getuigen</h4>
                            <div className="getuigen">
                                {
                                    schade.getuigen.value.map((item, index) =>  {
                                        return <div key={index} className="fields">
                                            <input type="text" placeholder="Naam" className={item.naam ? 'form-field valid' : 'form-field'} value={item.naam} onChange={(e) => handleGetuigenChange('naam', e.target.value, index)} disabled={isLoading} />
                                            <input type="text" placeholder="Adres" className={item.adres ? 'form-field valid' : 'form-field'} value={item.adres} onChange={(e) => handleGetuigenChange('adres', e.target.value, index)} disabled={isLoading} />
                                            <input type="text" placeholder="Telefoonnummer" className={item.telefoonnummer ? 'form-field valid' : 'form-field'} value={item.telefoonnummer} onChange={(e) => handleGetuigenChange('telefoonnummer', e.target.value, index)} disabled={isLoading} />
                                        </div>
                                    })
                                }
                                <div className="actions">
                                    {
                                        schade.getuigen.value.length > 1 ?
                                        <button className="remove" onClick={() => handleGetuigen('remove')}><Icon.DashCircleFill /></button>
                                        :
                                        null
                                    }  
                                    <button className="add" onClick={() => handleGetuigen('add')}><Icon.PlusCircleFill /></button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="documenten" className="block">
                        <div className="content">
                        <div className="block-header"><h3>Documenten</h3><Icon.FileEarmarkRichtext /></div>
                            {
                                Object.keys(documenten).map(key => {
                                    return documenten[key].show ? <FileUpload key={key} id={documenten[key].id} label={documenten[key].label} handleChange={handleChange} field={key} documenten={documenten} accept={documenten[key].accept} multiple={documenten[key].multiple} required={documenten[key].required} isLoading={isLoading} /> : null
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="checklist">
                    <div className="checklist-block">
                    <div className="block-header"><h3>Overzicht</h3><Icon.ListCheck /></div>
                    <div className="list">
                        {
                            legenda.map((item, index) =>  {
                                if (item.show) {
                                    return <a key={index} href={'#' + item.id} className="list-item"><Icon.CheckCircleFill color={item.valid ? '#00D100' : '#ADADAD'} /><span style={{color: (item.valid ? '#00D100' : '#001C43')}}>{item.name}</span></a>
                                }
                            })
                        }
                    </div>
                    {
                        legenda.every(item => !item.show || item.valid) && (soortSchade.verkeersschade.value || soortSchade.ladingschade.value || soortSchade.warehouse.value || soortSchade.persoonlijk.value) ?
                        <button onClick={() => uploadTheRest()}>Schade insturen</button>
                        :
                        <p className="error-message">Vul het formulier volledig in om te kunnen versturen</p>
                    }
                    </div>
                </div>    
            </div>
            
            <div className={'loader-dossier ' + (creatingDossier ? 'show' : '')}>
                <div className="loader-content">
                    <Lottie lottieRef={lottieAnimation} autoplay={true} animationData={creatingDossierAnimation} />
                    <p>Aanmaken dossier ...</p>
                </div>
            </div>

            <Rodal visible={showNewSelectOption} onClose={() => { setShowNewSelectOption(false); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3>{typeToAdd} toevoegen</h3>
                <form id="new-medewerker" onSubmit={(e) => newMedewerkerType ===  'charter' ? createNewCharter(e) : createMedewerker(e)}>
                    <input type="text" value={newMedewerker} onChange={(e) => setNewMedewerker(e.target.value)} className="field" required />
                    <input type="submit" value="Versturen" className="send" disabled={isLoading} />
                </form>
            </Rodal>

            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/schadeformulier" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Schadeformulier;