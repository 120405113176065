//3rd party
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';

//styles
import 'react-loading-skeleton/dist/skeleton.css';

const SchadeInformatie = (props) => {

    return (
        <table id="info">
            <tbody>
                {
                    props.werkbon ?
                    <tr>
                        <td>Trailer/aanhanger:</td>
                        <td>{props.schade ? props.schade.kenteken_trailer_aanhanger : <Skeleton height={20} />}</td>
                    </tr>
                    :
                    null
                }
                <tr>
                    <td>Land:</td>
                    <td>{props.schade ? props.schade.land : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Plaats:</td>
                    <td>{props.schade ? props.schade.plaats.naam : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Omschrijving:</td>
                    <td>{props.schade ? props.schade.schadeomschrijving : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Oorzaak:</td>
                    <td>{props.schade ? props.schade.schadeoorzaak : <Skeleton height={20} />}</td>
                </tr>
                <tr>
                    <td>Soort vervoer:</td>
                    <td>{props.schade ? props.schade.soort_vervoer.naam : <Skeleton height={20} />}</td>
                </tr>
                {
                    props.schade && props.schade.verhaal_eigen ?
                    <tr>
                        <td>Verhaal/eigen schade:</td>
                        <td>{props.schade ? props.schade.verhaal_eigen === 'verhaal' ? 'Verhaalschade' : 'Eigen schade' : <Skeleton height={20} />}</td>
                    </tr>
                    :
                    null
                }
                {
                    !props.werkbon ?
                    <tr>
                        <td>Actie richting chauffeur:</td>
                        <td>{props.schade ? props.schade.actie_richting_chauffeur : <Skeleton height={20} />}</td>
                    </tr>
                    :
                    null
                }
                
            </tbody>
        </table>
    );
};
export default SchadeInformatie;