import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//3rd party
import Marquee from "react-fast-marquee";
import { gsap } from "gsap";
import axios from 'axios';
import QRCode from "react-qr-code";

//Helper functions
import { getSettings } from '../../../settings/Helpers';

//Styles
import './narrowcasting.css';

//Assets
import vsdvLogo from '../../../assets/images/logo-white.png';
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

const NarrowcastingLiggend = () => {

    const { locatie } = useParams();
    const [width, setWidth] = useState('0%');
    const [transition, setTransition] = useState(false);
    let sliderTimer = 15000; // Initial slider timer
    let firstCallDone = false;
    let counter = 0;
    const [tickerItems, setTickerItems] = useState(['VSDV', 'VSDV', 'VSDV', 'VSDV', 'VSDV']);
    const [newsItems, setNewsItems] = useState([]);
    const [currentNewsItem, setCurrentNewsItem] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [loadingNewItem, setLoadingNewItem] = useState(false);
    const videoRef = useRef(null);

    const playVideo = () => {
        const video = videoRef.current;
        if (video && video.paused) {
            video.play().catch(error => {
                alert("Error playing video:");
            });
        }
    };

    const nextSlide = (data) => {
        if (data.length === 0) return;

        // Fading out current news item
        gsap.to(".news-meta .qr-code", { x: -100, opacity: 0, duration: 1, delay: 0.5 });
        gsap.to(".news-meta h1", { y: -50, opacity: 0, duration: 1, delay: 0.5 });
        gsap.to(".news-meta p", { y: -50, opacity: 0, duration: 1, delay: 0.7 });
        gsap.to(".news-content", { y: 100, opacity: 0, duration: 1, delay: 0.7 });
    
        gsap.to(".item-image img", { y: 50, duration: 0.5, delay: 1.5 });
        gsap.to(".overlay-next", { opacity: 1, duration: 0.5, delay: 1.5 });
    
        // In between
        gsap.to(".news-content", { y: -100, opacity: 0, delay: 2 });

        const emptyObj = {
            content: '',
            date: '',
            id: 0,
            image: '', 
            show_website: false,
            title: '',
            url: '',
            video: null
        };
        setTimeout(() => {
            setCurrentNewsItem(emptyObj);
            setLoadingNewItem(true);
        }, 2000);

        setTimeout(() => {
            setTransition(false);
            setWidth('0%');
            if (data[counter + 1]) {
                setCurrentNewsItem(data[counter + 1]);
                counter++;
            } else {
                setCurrentNewsItem(data[0]);
                counter = 0;
            }
            setLoadingNewItem(false);
        }, 2500);
    
        // Showing next news item
        gsap.to(".overlay-next", { opacity: 0, duration: 0.5, delay: 2.5 });
        gsap.to(".item-image img", { y: 0, duration: 0.5, delay: 2.5 });
        gsap.to(".news-meta .qr-code", { x: 0, opacity: 1, duration: 1, delay: 3.5 });
        gsap.to(".news-meta h1", { y: 0, opacity: 1, duration: 1, delay: 3 });
        gsap.to(".news-meta p", { y: 0, opacity: 1, duration: 1, delay: 4.5 });
        gsap.to(".news-content", { y: 0, opacity: 1, duration: 1, delay: 5.5 });
    
        setTimeout(() => {
            setTransition(true);
            setWidth('100%');
            //playVideo();
        }, 5000);
    
        // Check if it's the first call
        if (!firstCallDone) {
            firstCallDone = true;
            sliderTimer = 21000; // Change sliderTimer after the first call
    
            // Set the interval for subsequent calls with the new sliderTimer
            setInterval(() => {
                if (data.length - counter === 1) {
                    window.location.reload();
                } else {
                    nextSlide(data);
                }
            }, sliderTimer);
        }
    }
    
    const slidesInit = (data) => {
        setNewsItems(data);
        setCurrentNewsItem(data[0]);
        setTransition(true);
        setWidth('100%');
    
        // Use setTimeout for the first call to nextSlide with the initial sliderTimer
        setTimeout(() => {
            nextSlide(data);
        }, sliderTimer);
    }

    const getNieuws = async () => {
        try {
            const response = await axios.post('https://vsdv.nl/wp-json/api/v2/nieuws/' + locatie);
            if (response.data.data.length > 0) {
                let copiedArray = [];
                for (let i = 0; i < 5; i++) {
                    copiedArray = copiedArray.concat(response.data.data);
                }
                slidesInit(copiedArray);
                setIsLoading(false);
            } else {
                alert('Niks gevonden');
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getNieuwsTicker = async () => {
        try {
            const response = await axios.post('https://vsdv.nl/wp-json/api/v2/nieuws-ticker/' + locatie);
            if (response.data.data.length > 0) {
                setTickerItems(response.data.data);
                getNieuws();
            } else {
                console.log('Niks gevonden');
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getNieuwsTicker();
        // const video = videoRef.current;
        // if (video) {
        //     video.addEventListener('click', playVideo);
        // }
        // return () => {
        //     if (video) {
        //         video.removeEventListener('click', playVideo);
        //     }
        // };
      }, []);
    

    return (
        <div id="narrowcasting-vsdv">
            <div className="item-image">
                {
                    !loadingNewItem && currentNewsItem.video ?
                    <video id="news-video" ref={videoRef} autoPlay controls muted loop>
                        <source src={currentNewsItem.video} type="video/mp4" />
                    </video>
                    :
                    <img src={currentNewsItem.image} />
                }   
                <div className="news-meta">
                    {
                        currentNewsItem.show_website ?
                        <div className="qr-code"><QRCode value={currentNewsItem.url ? currentNewsItem.url : 'VSDV'} bgColor={'rgba(255,255,255,0.0'} fgColor={'#ffffff'} size={100} /></div>
                        :
                        <div className="qr-code"></div>
                    }
                    <h1>{currentNewsItem.title}</h1>
                    <p>{currentNewsItem.date}</p>
                </div>
                <div className="overlay"></div>
                <div className="overlay-next"></div>
                <div className={'animated-loader ' + (transition ? '' : 'stop')} style={{ width }}></div>
            </div>
            <div className="item-content">
                <div className="news-content" dangerouslySetInnerHTML={{ __html: currentNewsItem.content }} />
            </div>
            {
                !isLoading && tickerItems.length > 0 ?
                <div className="ticker">
                    <Marquee>
                        {
                            tickerItems.map((item, index) =>  {
                                return <p key={index}>{item.title}</p>
                            })
                        }
                    </Marquee>
                </div>
                :
                null
            }
            
            <img src={vsdvLogo} className="nc-logo" />
            <img src={vsdvArtwork} className="nc-artwork" />
        </div>
      );

  
};
export default NarrowcastingLiggend;