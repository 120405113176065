import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, getTimeFromSeconds, countTotalSeconds } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert';
import Rodal from 'rodal';
import YouTube from 'react-youtube';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import Registratiedag from '../../../components/registreren/registratiedag';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './registreren.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'rodal/lib/rodal.css';

const Registreren = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const videotutorials = JSON.parse(localStorage.getItem('videotutorials'));
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [allRegistraties, setAllRegistraties] = useState([]);
    const [registraties, setRegistraties] = useState([]);
    const arrayDays = ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'];
    const [weekNumber, setWeekNumber] = useState(moment().isoWeek());
    const [previousWeek, setPreviousWeek] = useState(true);
    const [nextWeek, setNextWeek] = useState(true);
    const [previousWeeksOpen, setPreviousWeeksOpen] = useState(false);
    const [totalHours, setTotalHours] = useState('00:00');
    const [totalHoursValid, setTotalHoursValid] = useState(true);
    const [verlof, setVerlof] = useState([]);
    const [verlofHours, setVerlofHours] = useState('00:00');
    const [contractHours, setContractHours] = useState('00:00');
    const [urenTypes, setUrenTypes] = useState([]);
    const [loadingRegistraties, setLoadingRegistraties] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [noRegistrations, setNoRegistrations] = useState(false);
    const [maxRows, setMaxRows] = useState(0);
    const [longBreak, setLongBreak] = useState(0);
    const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
    const youtubePlayer = useRef(null);
    const opts = {
        height: '280',
        width: '500',
        playerVars: {
          autoplay: 0,
        },
    };

    const handleTotalHours = (uren) => {
        var arrayDays = [uren.zondag, uren.maandag, uren.dinsdag, uren.woensdag, uren.donderdag, uren.vrijdag, uren.zaterdag];
        var seconds = 0;
        arrayDays.map((item, index) =>  {
            seconds += countTotalSeconds(item);
        });
        const total = getTimeFromSeconds(seconds);
        if (total.includes('NaN')) {
            setTotalHoursValid(false);
        } else {
            setTotalHoursValid(true);
            setTotalHours(getTimeFromSeconds(seconds));
        }
    }

    //Function to set the week to view
    const setRegistratiesByWeekNumber = (arrayRegistraties, week) => {
        const lastWeekOfYear = moment().weeksInYear() === week;
        const result = arrayRegistraties.find(item => item.weeknummer === week);
        const index = arrayRegistraties.findIndex(item => item.weeknummer === week);
        if (week === 1) {
            var previous = arrayRegistraties.find(item => item.weeknummer === 52);
        } else {
            var previous = arrayRegistraties.find(item => item.weeknummer === (week - 1));
        }
        if (lastWeekOfYear) {
            var next = arrayRegistraties.find(item => item.weeknummer === 1);
        } else {
            var next = arrayRegistraties.find(item => item.weeknummer === (week + 1));
        }
        var openStatusesInPast = false;

        for (var i = index - 1; i >= 0; i--) {
            if (arrayRegistraties[i].status === 'open' || arrayRegistraties[i].status === 'deels_ingestuurd' || arrayRegistraties[i].status === 'deels_goedgekeurd' || arrayRegistraties[i].status === 'deels_goedgekeurd_def') {
                openStatusesInPast = true;
            }
        }
        if (index === 0) {
            setPreviousWeeksOpen(false);
        }
        if (openStatusesInPast) {
            setPreviousWeeksOpen(true);
        } else {
            setPreviousWeeksOpen(false);
        }

        if (!result) {
            setRegistraties(arrayRegistraties[arrayRegistraties.length - 1]);
            handleTotalHours(arrayRegistraties[arrayRegistraties.length - 1]);
            setWeekNumber(arrayRegistraties[arrayRegistraties.length - 1].weeknummer);
        } else {
            setRegistraties(result);
            handleTotalHours(result);
            setCurrentMonth(moment(result.maandag.datum).format('MMMM'));
        }
        
        if (!previous) { 
            setPreviousWeek(false);
        } else {
            setPreviousWeek(true);
        }
        if (!next) { 
            setNextWeek(false);
        } else {
            setNextWeek(true);
        }
    }
    
    const getUrenTypes = async () => {
        try {
            const response = await api.get('/urentypes');
            if (response.data) {
                setUrenTypes(response.data);
            }
        } catch (error) {}
    }

    //Function to get registraties
    const getRegistraties = async () => {
        setLoadingRegistraties(true);
        try {
            const response = await api.get('/registraties?me=true');
            if (response.data) {
                if (response.data[0].registraties.length === 0) {
                    setNoRegistrations(true);
                    setLoadingRegistraties(false);
                    return;
                }
                //const result = response.data[0].registraties.sort((a, b) => (a.weeknummer > b.weeknummer) ? 1 : -1);
                const result = response.data[0].registraties.sort((a, b) => {
                    // Compare years first
                    if (a.jaar !== b.jaar) {
                        return a.jaar - b.jaar;
                    }
                    // If years are the same, compare week numbers
                    return a.weeknummer - b.weeknummer;
                });
                setAllRegistraties(result);
                setRegistratiesByWeekNumber(result, weekNumber);
                setContractHours(response.data[0].contracturen);
                setMaxRows(response.data[0].maximum_rijen);
                setLongBreak(response.data[0].lange_pauze);
                setNoRegistrations(false);
                setLoadingRegistraties(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                    navigate('/login');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT. Uitloggen en weer inloggen kan soms ook helpen.');
                    navigate('/login');
            }
        }
    }

    const saveRegistratie = async () => {
        setIsLoading(true);
        try {
            const response = await toast.promise(
                api.put('/registraties/:id', {
                    "data" : registraties
                }),
                {
                    loading: 'Opslaan...',
                    success: <b>Uren opgeslagen</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                console.log(registraties);
                console.log(response.data);
                setIsLoading(false);
            }
        } catch (error) {}
    }
    
    const submitRegistratie = async (status) => {
        registraties.status = status;
        // if (status === 'deels_ingestuurd') {
        //     var successResponse = 'Uren ' + currentMonth + ' zijn ingestuurd';
        // } else {
        //     if (medewerker.loonperiode === 'per_maand' && registraties.maanden === 'two') {
        //         var successResponse = 'Overige uren zijn ingestuurd';
        //     } else {
        //         var successResponse = 'Uren zijn ingestuurd';
        //     }
        // }
        var successResponse = 'Uren zijn ingestuurd';
        setIsLoading(true);
        try {
            const response = await toast.promise(
                api.put('/registraties/:id', {
                    "data" : registraties
                }),
                {
                    loading: 'Opslaan...',
                    success: <b>{successResponse}</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                getRegistraties();
                setIsLoading(false);
            }
        } catch (error) {}
    }

    const confirmSaveRegistratie = (status) => {
        confirmAlert({
            title: 'Na het insturen van de uren zullen deze worden beoordeeld.',
            message: 'Wil je doorgaan?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => submitRegistratie(status)
              },
              {
                label: 'Nee'
              }
            ]
          });
    }

    const restoreRegistratie = async (status) => {
        setIsLoading(true);
        try {
            const response = await toast.promise(
                api.put('/registraties/:id', {
                    "data": {
                        "id": registraties.id,
                        "status": "restore"
                    }
                }),
                {
                    loading: 'Terugzetten...',
                    success: <b>Terug gezet</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                getRegistraties();
                setIsLoading(false);
            }
        } catch (error) {}
    }

    //Check if user is authenticated and else redirect to login, else get registraties
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getRegistraties();
        getUrenTypes();
    }, []);
    
    if (loggedIn === 'true') {
        return (
        <div id="main" className="dashboard">
            <SideNav page="/registreren" />
            <div className="dashboard-content">
                <DashboardHeader title="Uren registreren" />
                {
                    videotutorials.uren_registreren ?
                    <button className="help-button" onClick={() => setShowModalHelp(true)}><Icon.QuestionCircle /><span>Help</span></button>
                    :
                    null
                }
                
                {
                    noRegistrations ?
                    <h2>Er zijn geen uren om in te vullen</h2>
                    :
                    <>
                    <div className="registreren-header">
                        {
                            previousWeek ?
                            <div className="button-week left">
                                <button disabled={isLoading} onClick={() => { setRegistratiesByWeekNumber(allRegistraties, weekNumber === 1 ? 52 : weekNumber - 1); setWeekNumber(weekNumber === 1 ? 52 : weekNumber - 1) }}><Icon.ArrowLeftCircle /><span>Week {weekNumber === 1 ? 52 : weekNumber - 1}</span></button>
                            </div>
                            :
                            <div className="empty"></div>
                        }
                        <div className="current">
                            <p className="current-week">Week {weekNumber}</p>
                        </div>
                        {
                            nextWeek ?
                            <div className="button-week right">
                                <button disabled={isLoading} onClick={() => { setRegistratiesByWeekNumber(allRegistraties, moment().weeksInYear() === weekNumber ? 1 : weekNumber + 1); setWeekNumber(moment().weeksInYear() === weekNumber ? 1 : weekNumber + 1) }}><span>Week {moment().weeksInYear() === weekNumber ? 1 : weekNumber + 1}</span><Icon.ArrowRightCircle /></button>
                            </div>
                            :
                            <div className="empty"></div>
                        }
                        
                    </div>
                    {
                        registraties && Object.keys(registraties).length ?
                        <div className="days">
                            {
                                arrayDays.map((item, index) =>  {
                                    return <Registratiedag 
                                        key={index} 
                                        day={item} 
                                        registraties={registraties} 
                                        status={registraties.status}
                                        currentMonth={currentMonth} 
                                        dayInfo={registraties[item]} 
                                        setAllRegistraties={setAllRegistraties} 
                                        allRegistraties={allRegistraties}
                                        urenTypes={urenTypes} 
                                        weekNumber={weekNumber} 
                                        getTimeFromSeconds={getTimeFromSeconds} 
                                        handleTotalHours={handleTotalHours}
                                        setTotalHoursValid={setTotalHoursValid}
                                        verlof={verlof}
                                        maxRows={maxRows}
                                        longBreak={longBreak}
                                        medewerker={medewerker}
                                        notifyError={notifyError}
                                    />
                                })
                            }
                        </div>
                        :
                        <div>
                            <Skeleton height={100} width={'100%'} style={{marginBottom:20}} />
                            <Skeleton height={100} width={'100%'} style={{marginBottom:20}} />
                            <Skeleton height={100} width={'100%'} style={{marginBottom:20}} />
                        </div>
                    }
                    <div className="registreren-footer">
                        <div className="registreren-footer-content">
                            <div className="totals">
                                <table id="info">
                                    <tbody>
                                    <tr>
                                        <td>Totaal uren:</td>
                                        <td>{!totalHoursValid ? 'Uren niet correct ingevuld' : totalHours}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Verlofuren:</td>
                                        <td>{verlofHours}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Contracturen:</td>
                                        <td>{contractHours}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            {
                                registraties.status === 'ingestuurd' || registraties.status === 'goedgekeurd' || registraties.status === 'goedgekeurd_def' ?
                                <div className="status-info">
                                        <p><strong>{registraties.status === 'goedgekeurd' || registraties.status === 'goedgekeurd_def' ? 'Uren zijn goedgekeurd' : 'Uren zijn ingestuurd'}</strong></p>
                                        {
                                            registraties.status === 'ingestuurd' ?
                                            <p>Wil je toch nog iets wijzigen?<br/>Klik dan <span className="restore" onClick={() => restoreRegistratie()}>hier</span></p>
                                            :
                                            null
                                        } 
                                </div>    
                                :
                                <>
                                {
                                    !totalHoursValid ?
                                    null
                                    :
                                    <>
                                        <div className="footer-buttons">
                                            <button className="save" disabled={isLoading} onClick={() => saveRegistratie()}><span>Tussentijds opslaan</span></button>
                                            {
                                                previousWeeksOpen ?
                                                <p className="error">Je kunt de uren van deze week pas insturen als je de uren van eerdere weken hebt ingestuurd. Je kunt wel alvast je uren invullen en tussentijds opslaan.</p>
                                                :
                                                <button className="submit" disabled={isLoading} onClick={() => confirmSaveRegistratie('ingestuurd')}><Icon.CheckLg />
                                                    <span>Uren insturen</span>
                                                </button>
                                            }
                                            
                                        </div>
                                    </>
                                }
                                </>
                            }
                        </div>
                    </div>
                    </>
                }
                <Rodal visible={showModalHelp} onClose={() => { setShowModalHelp(false); youtubePlayer.current.internalPlayer.pauseVideo(); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                    <h3>Uitleg uren registreren</h3>
                    <YouTube videoId={videotutorials.uren_registreren} opts={opts} ref={youtubePlayer} />
                </Rodal>
                <Toaster position="bottom-center" />
            </div>
            <BottomNav page="/registreren" />
            <img src={vsdvArtwork} className="artwork" />
        </div>
        );
    }
};
export default Registreren;