import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';
import { Drawer, Timeline, Loader } from 'rsuite';
import { confirmAlert } from 'react-confirm-alert';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import mailerSendImage from '../../../assets/images/mailersend.png';
import iconTranspas from '../../../assets/images/icon-transpas.png';

//styles
import './dieselpercentages.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';
import 'rsuite/dist/rsuite-no-reset.min.css';

//Inline components
const Actions = (props) => <div className="actions schade-actions">
    {/* <button onClick={() => {
        props.setDrawerTitle(props.medewerker.gebruiker.role.type === 'super_admin' ? 'Dieselpercentages versturen ' + props.item.naam : 'Dieselpercentages ' + props.item.naam); props.getDotInfo(props.item); }} className="button green">
        {props.medewerker.gebruiker.role.type === 'super_admin' ? 'Versturen' : 'DOT'}
    </button> */}
    <button onClick={() => {props.setDrawerTitle(props.item.naam); props.assignDebiteur(props.item); }} className="button orange">Info</button>
    {
        props.medewerker.gebruiker.role.type === 'super_admin' ?
        <Icon.Trash3Fill color="#BB0024" size={16} className="delete-debiteur" onClick={() => props.confirmDeleteDebiteur(props.item.id)}/>
        :
        null
    }
    
</div>

const Debiteuren = () => {

    //Set variables
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_APP_URL;
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const dieselpercentages_inzien = medewerker.dieselpercentages_inzien ? medewerker.dieselpercentages_inzien : false;
    const [debiteuren, setDebiteuren] = useState([]);
    const [debiteur, setDebiteur] = useState(0);
    const [filterText, setFilterText] = useState('');
    const [newDebiteur, setNewDebiteur] = useState(false);
    const [loadingDebiteuren, setLoadingDebiteuren] = useState(true);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerTitle, setDrawerTitle] = useState('');
    const [openDrawerMailerSend, setOpenDrawerMailerSend] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [page, setPage] = useState(1);
    const [mailLogs, setMailLogs] = useState([]);
    const [debiteurInfo, setDebiteurInfo] = useState({
        valid: false,
        fields: {
            naam: '',
            debiteurnummer: '',
            url: '',
            logs: [],
            contacts: []
        }
    });
    const [loadingDebiteurInfo, setLoadingDebiteurInfo] = useState(false);
    const [sendingDot, setSendingDot] = useState(false);
    const [isSendingDot, setIsSendingDot] = useState(false);
    const [week, setWeek] = useState(moment().isoWeek() + 1);
    const initialWeek = moment().isoWeek() + 1;
    const [year, setYear] = useState(moment().format('YYYY'));
    const [loadingDotInfo, setLoadingDotInfo] = useState(false);
    const [dotRows, setDotRows] = useState([]);
    const [countDifferentDots, setCountDifferentDots] = useState(0);
    const [dotInfo, setDotInfo] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [urlOptions, setUrlOptions] = useState([
        {
            label: 'OK Oliecentrale',
            value: 'https://www.ok-oliecentrale.nl/lijstprijs'
        },
        {
            label: 'BP',
            value: 'https://www.bp.com/nl_nl/netherlands/home/producten-en-services/bp-brandstoffen/landelijke-adviesprijzen.html'
        },
        {
            label: 'Evofenedex',
            value: 'https://www.evofenedex.nl/kennis/vervoer/duurzaam-vervoer/brandstof/dieselprijzen-nederland'
        },
        {
            label: 'TLN',
            value: 'https://www.tln.nl/ledenvoordeel/brandstofmonitor'
        },
        {
            label: 'United Consumers',
            value: 'https://www.unitedconsumers.com/brandstofprijzen'
        }
    ]);

    const formatBedrag = (value) => {
        var formatted = Number(value).toFixed(4);
        formatted = formatted.toString().replace(".", ",");
        return formatted;
    }

    //Function to sort on string value
    const filteredData = debiteuren.filter((item) => {
        const columnsToSearch = ['debiteur', 'debiteurnummer', 'url'];
        for (let i = 0; i < columnsToSearch.length; i++) {
          const column = columnsToSearch[i];
          var cellData = item[column].toLowerCase();
          if (cellData.includes(filterText)) {
            return true;
          }
        }
        return false;
    });

    //Columns for datatable
    const columns = [
        {
            name: 'Debiteur',
            selector: row => row.debiteur,
            sortable: true,
            hide: 'md'
        },
        {
            name: 'Debiteurnummer',
            selector: row => row.debiteurnummer,
            sortable: true,
            hide: 'md'
        },
        {
            name: 'URL',
            selector: row => row.url,
            sortable: true,
        },
        {
            name: 'Week laatst verstuurd',
            selector: row => row.laatst_gestuurde_week,
            sortable: true,
        },
        {
            name: 'Acties',
            selector: row => row.acties,
        }
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };

    const assignDebiteur = async (item) => {
        setLoadingDebiteurInfo(true);
        const current = {...debiteurInfo};
        setDebiteur(item.id);
        current.fields.naam = item.naam;
        current.fields.debiteurnummer = item.debiteurnummer;
        current.fields.url = item.url;
        var logs = [];
        if (item.logs.length > 0) {
            item.logs.map((item, index) =>  {
                item.statusMailerSend = {subject: '', status: ''};
                item.loadingMailerSend = false;
            })
            logs = item.logs;
        }
        current.fields.logs = logs;
        const contacts = await getContactpersons(item);
        current.fields.contacts = contacts;
        setDebiteurInfo(current);
        setNewDebiteur(false);
        setSendingDot(false);
        setLoadingDebiteurInfo(false);
        setOpenDrawer(true);
    }

    const getContactpersons = async (item) => {
        try {
            const response = await api.get('/debiteuren/contactpersonen/' + item.debiteurnummer);
            if (response.data) {
                return response.data;
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getMailerSendLogs = async (event) => {
        event.preventDefault();
        const fromMoment = moment(fromDate);
        const toMoment = moment(toDate);
        const today = moment();
        if (fromMoment.isSameOrAfter(toMoment)) {
            return notifyError('Datum tot moet hoger zijn dan datum van');
        }
        if (toMoment.isAfter(today, 'day')) {
            return notifyError('Datum tot moet kan niet hoger zijn dan vandaag');
        }
        try {
            const response = await api.post('/debiteuren/mailersend-logs', {
                data: {
                    from: moment(fromDate).unix(),
                    to: moment(toDate).unix(),
                    page: page
                }
            });
            if (response.data) {
                setMailLogs(response.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getFirstFridayInPast = (fromDate) => {
        let currentDay = new Date(fromDate);
        while (currentDay.getDay() !== 5) {
          currentDay.setDate(currentDay.getDate() - 1);
        }
        return currentDay;
      }

    const getIndicesForDateRange = (data, monday, sunday) => {
        const indices = [];
        for (let i = 0; i < data.length; i++) {
            const dateFrom = moment(data[i].DateFrom);
            if (dateFrom.isBetween(monday, sunday, null, '[]')) {
                indices.push(i);
            }
        }
        return indices;
    }

    const findDotPrices = (data, weekNumber, yearNumber, differentDots) => {
        var current = [...dotInfo];
        const monday = moment().year(yearNumber).isoWeek(weekNumber).startOf('isoWeek');
        const sunday = monday.clone().endOf('isoWeek');
        const indices = getIndicesForDateRange(data, monday, sunday);
        if (indices.length > 0) {
            for (let i = 0; i < differentDots; i++) {
                const index = indices[i];
                const obj = {
                    name: data[index] ? data[index].Description : 'Onbekend',
                    peildatum: data[index] ? getFirstFridayInPast(data[index].DateFrom) : null,
                    dieselprijs: data[index] ? data[index].FuelPrice : null,
                    basisprijs_dot: data[index] ? data[index].BasePrice : null,
                    ingang: data[index] ? data[index].DateFrom : null,
                    dieselpercentage: data[index] ? data[index].Percentage : null
                };
                current[i] = obj;
            }
        } else {
            current = [];
        }
        setDotInfo(current);
    }

    const getDotInfo = async (item) => {
        setLoadingDotInfo(true);
        setWeek(initialWeek);
        setDebiteur(item.id);
        const currentDebiteurInfo = {...debiteurInfo};
        var logs = [];
        if (item.logs.length > 0) {
            item.logs.map((item, index) =>  {
                item.statusMailerSend = {subject: '', status: ''};
                item.loadingMailerSend = false;
            })
            logs = item.logs;
        }
        currentDebiteurInfo.fields.logs = logs;
        setDebiteurInfo(currentDebiteurInfo);
        var current = [...contacts];
        try {
            const response = await api.get('/debiteuren/dot-info/' + item.debiteurnummer);
            if (response.data) {
                const contacts = await getContactpersons(item);
                current = contacts;
                setContacts(current);
                setDotRows(response.data);
                let data = response.data;
                const uniqueFuelGroupPathPeriodnrValues = [...new Set(data.map(obj => obj.FuelGroupPathPeriodnr))];
                if (uniqueFuelGroupPathPeriodnrValues.length > 1) {
                    const minFuelGroupPathPeriodnr = Math.min(...uniqueFuelGroupPathPeriodnrValues);
                    data = data.filter(obj => obj.FuelGroupPathPeriodnr !== minFuelGroupPathPeriodnr);
                }
                const flattenFuelGroups = data.flatMap(obj => Array.isArray(obj.FuelGroup) ? obj.FuelGroup : [obj.FuelGroup]);
                const uniqueFuelGroups = new Set(flattenFuelGroups);
                const numberOfUniqueFuelGroups = uniqueFuelGroups.size;
                setCountDifferentDots(numberOfUniqueFuelGroups);
                findDotPrices(response.data, initialWeek, Number(year), numberOfUniqueFuelGroups);
            }
        } catch (error) {
            console.log(error);
        }
        setLoadingDotInfo(false);
        setSendingDot(true);
        setOpenDrawer(true);
    }

    const sendDot = async (event) => {
        event.preventDefault();
        if (dotInfo.length < 1) {
            return notifyError('Er is geen DOT informatie bekend dus kan er niks verstuurd worden');
        } else {
            const hasEmptyValue = dotInfo.some(item => 
                Object.values(item).some(value => value === '' || value === null)
            );
            if (hasEmptyValue) {
                return notifyError('DOT informatie is niet compleet dus kan niet worden verstuurd');
            }
        }
        if (contacts.length < 1) {
            return notifyError('Er zijn geen contactpersonen waar de DOT naar gestuurd kan worden');
        }
        setIsSendingDot(true);
        try {
            const response = await api.post('/debiteuren/stuur-dot-info', {
                data: {
                    debiteur: debiteur,
                    dotInfo: dotInfo,
                    week: week,
                    contacts: contacts
                }
            });
            if (response.data) {
                const currentDebiteurInfo = {...debiteurInfo};
                var logs = [];
                if (response.data.logs.length > 0) {
                    response.data.logs.map((item, index) =>  {
                        item.statusMailerSend = {subject: '', status: ''};
                        item.loadingMailerSend = false;
                    })
                    logs = response.data.logs;
                }
                currentDebiteurInfo.fields.logs = logs;
                setDebiteurInfo(currentDebiteurInfo);
                getDebiteuren();
                notifySuccess('DOT is verstuurd');
                setIsSendingDot(false);
            }
        } catch (error) {
            console.log(error);
            notifyError('Er is iets mis gegaan met het versturen van de DOT');
        }
    }

    const addNewDebiteur = () => {
        const current = {...debiteurInfo};
        setDebiteur(0);
        setNewDebiteur(true);
        setSendingDot(false);
        setDrawerTitle('Nieuwe debiteur toevoegen')
        current.fields.naam = '';
        current.fields.debiteurnummer = '';
        current.fields.url = '';
        current.fields.logs = [];
        setOpenDrawer(true);
    }

    const handleChange = (section, field, value) => {
        const current = {...debiteurInfo};
        if (field === 'debiteurnummer') {
            value = value.replace(/\D/g, '');
        }
        current.fields[field] = value;
        setDebiteurInfo(current);
    }

    const callMailerSend = async (id) => {
        try {
            const response = await api.get('/debiteuren/mail-info/' + id);
            if (response.data.data) {
                return response.data.data;
            }
        } catch (error) {
            return false;
        }
    }

    const getMailerSendInfo = async (index) => {
        const current = {...debiteurInfo};
        current.fields.logs[index].loadingMailerSend = true;
        setDebiteurInfo(current);
        const logItem = current.fields.logs[index];
        const mailerSendResource = logItem.toelichting;
        const mailerSendInfo = await callMailerSend(mailerSendResource);
        const newCurrent = {...debiteurInfo};
        if (mailerSendInfo.emails) {
            newCurrent.fields.logs[index].statusMailerSend = {
                subject: mailerSendInfo.emails[0].subject,
                status: mailerSendInfo.emails[0].status
            }
        } else {
            newCurrent.fields.logs[index].statusMailerSend = {subject: '', status: ''};
        }
        newCurrent.fields.logs[index].loadingMailerSend = false;
        setDebiteurInfo(newCurrent);
    }

    const confirmDeleteDebiteur = (id) => {
        confirmAlert({
            title: 'Hiermee wordt deze debiteur definitief verwijderd.',
            message: 'Wil je doorgaan?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => deleteDebiteur(id)
              },
              {
                label: 'Nee'
              }
            ]
          });
    }

    const deleteDebiteur = async (id) => {
        try {
            const response = await api.delete('/debiteuren/' + id);
            if (response.data) {
                notifySuccess('Debiteur is definitief verwijderd');
                getDebiteuren();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const submitDebiteur = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/debiteuren', {
                data: {
                    naam: debiteurInfo.fields.naam,
                    debiteurnummer: debiteurInfo.fields.debiteurnummer,
                    url: debiteurInfo.fields.url
                }
            });
            if (response.data) {
                setOpenDrawer(false);
                notifySuccess('Debiteur is aangemaakt');
                getDebiteuren();
            }
        } catch (error) {
            console.log(error);
            if (error.response.data.error.details.errors[0].path.includes("debiteurnummer")) {
                notifyError('Debiteurnummer is al in gebruik, voer een ander debiteurnummer in');
            }
        }
    }

    const changeDebiteur = async (event) => {
        event.preventDefault();
        try {
            const response = await api.put('/debiteuren/' + debiteur, {
                data: {
                    naam: debiteurInfo.fields.naam,
                    debiteurnummer: debiteurInfo.fields.debiteurnummer,
                    url: debiteurInfo.fields.url
                }
            });
            if (response.data) {
                setLoadingDebiteuren(true);
                setOpenDrawer(false);
                notifySuccess('Debiteur is bijgewerkt');
                getDebiteuren();
            }
        } catch (error) {
            if (error.response.status === 400) {
                notifyError(error.response.data.error.message);
            } else {
                console.log(error);
            }
        }
    }

    //Function to get schades
    const getDebiteuren = async () => {
        setLoadingDebiteuren(true);
        try {
            const response = await api.get('/debiteuren');
            if (response.data) {
                var arrayData = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        id: item.id,
                        debiteur: item.naam,
                        debiteurnummer: item.debiteurnummer,
                        url: item.url,
                        laatst_gestuurde_week: item.laatst_gestuurde_week,
                        acties: <Actions item={item} setDrawerTitle={setDrawerTitle} assignDebiteur={assignDebiteur} getDotInfo={getDotInfo} confirmDeleteDebiteur={confirmDeleteDebiteur} medewerker={medewerker} />,
                    };
                    arrayData.push(obj);
                })
                setDebiteuren(arrayData);
                setLoadingDebiteuren(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (!dieselpercentages_inzien) {
            navigate('/');
        }
        getDebiteuren();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/debiteuren" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Debiteuren" />
            {
                medewerker.gebruiker.role.type === 'super_admin' ?
                <>
                    <button onClick={addNewDebiteur} className="new-debiteur button green">Nieuwe debiteur toevoegen</button>
                    <button onClick={() => setOpenDrawerMailerSend(true)} className="mail-logs"><img src={mailerSendImage} /></button>
                </>
                :
                null
            }
            <input
                type="text"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                placeholder="Zoeken..."
                className="search-debiteur"
            />
            {
                loadingDebiteuren ?
                <>
                <Skeleton height={40} style={{marginBottom:10,marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                {
                    debiteuren.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        fixedHeader
                        paginationPerPage={10}
                        defaultSortFieldId={1}
                    />
                    :
                    <p>Er zijn geen debiteuren gevonden.</p>
                }
                </>
            }
            <Toaster />
        </div>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <Drawer.Header>
                <Drawer.Title>{drawerTitle}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                {
                    !sendingDot ?
                    <form id="submit-debiteur" className="drawer-form" onSubmit={newDebiteur ? submitDebiteur : changeDebiteur}>
                        <div className="two-column">
                            <div className="field">
                                <label>Naam</label>
                                <input type="text" placeholder="Naam" value={debiteurInfo.fields.naam} className={'form-field'} onChange={(e) => handleChange('debiteurInfo', 'naam', e.target.value)} required disabled={medewerker.gebruiker.role.type !== 'super_admin'} />
                            </div>
                            <div className="field">
                                <label>Debiteurnummer</label>
                                <input type="text" placeholder="Debiteurnummer" value={debiteurInfo.fields.debiteurnummer} className={'form-field'} onChange={(e) => handleChange('debiteurInfo', 'debiteurnummer', e.target.value)} required disabled={medewerker.gebruiker.role.type !== 'super_admin'} />
                            </div>
                        </div>
                        <div className="field">
                            <label>URL</label>
                            <select defaultValue={debiteurInfo.fields.url} className={'form-field'} onChange={e => handleChange('debiteurInfo', 'url', e.target.value)} required>
                                <option value={''}>Alle urls</option>
                                {
                                        urlOptions.map((item, index) =>  {
                                            return <option key={index} value={item.value}>{item.label}</option>
                                        })
                                }
                            </select>
                        </div>
                        {
                            medewerker.gebruiker.role.type === 'super_admin' || dieselpercentages_inzien ?
                            <input type="submit" value={newDebiteur ? 'Aanmaken' : 'Opslaan'} className="submit-form" />
                            :
                            null
                        }
                        
                        {
                            !loadingDebiteurInfo ?
                            <div className="contacts-transpas">
                                <div className="contacts-transpas-content">
                                    <h4>Contactpersonen Transpas</h4>
                                    {
                                        debiteurInfo.fields.contacts.length > 0 ?
                                        <>
                                        {
                                            debiteurInfo.fields.contacts.map((item, index) =>  {
                                                return <p key={index}>{item.EMail}</p>
                                            })
                                        }
                                        </>
                                        :
                                        <p>Er zijn geen contactpersonen in Transpas gevonden...</p>
                                    }
                                </div>
                                <img src={iconTranspas} className="logo-transpas" />
                            </div>
                            :
                            null
                        }
                    </form>
                    :
                    <>
                    <form id="send-dot" className="drawer-form" onSubmit={sendDot} onKeyDown={(e) => e.key === 'Enter' ? e.preventDefault() : null}>
                        <div className="two-column">
                            <div className="field">
                                <label>Weeknummer</label>
                                <input type="number" placeholder="Weeknummer" value={week} className={'form-field'} onChange={(e) => {setWeek(e.target.value); findDotPrices(dotRows, e.target.value, year, countDifferentDots);}} required />
                            </div>
                            <div className="field">
                                <label>Jaar</label>
                                <input type="number" placeholder="Jaar" value={year} className={'form-field'} onChange={(e) => {setYear(e.target.value); findDotPrices(dotRows, week, e.target.value, countDifferentDots);}} required />
                            </div>
                        </div>
                        {
                            dotInfo ?
                            <div className="dot-info-transpas">
                                <h4>DOT informatie uit Transpas week {week} - {year}</h4>
                                {
                                    loadingDotInfo ?
                                    <Loader />
                                    :
                                    <>
                                    {
                                        dotInfo.length > 0 ?
                                        <>
                                        {Array.from({ length: countDifferentDots }, (_, index) => (
                                            <div key={index} className="dot-row">
                                                <p style={{textAlign:'center',color:'#FF7B00'}}>{dotInfo[index].name}</p>
                                                <div key={index} className="dot-info-content">
                                                    <div className="dot-block one-half"><span>Peildatum:</span><p>{dotInfo[index].peildatum ? moment(dotInfo[index].peildatum).format('DD-MM-YYYY') : ''}</p></div>
                                                    <div className="dot-block one-half"><span>Dieselprijs:</span><p>{dotInfo[index].dieselprijs ? '€ ' + formatBedrag(dotInfo[index].dieselprijs) : ''}</p></div>
                                                    <div className="dot-block one-third"><span>Basisprijs DOT:</span><p>{dotInfo[index].basisprijs_dot ? '€ ' + formatBedrag(dotInfo[index].basisprijs_dot) : ''}</p></div>
                                                    <div className="dot-block one-third"><span>Ingang:</span><p>{dotInfo[index].ingang ? moment(dotInfo[index].ingang).format('DD-MM-YYYY') : ''}</p></div>
                                                    <div className="dot-block one-third"><span>Dieselpercentage:</span><p>{dotInfo[index].dieselpercentage !== null ? dotInfo[index].dieselpercentage + ' %' : ''}</p></div>
                                                </div>
                                            </div>
                                        ))}
                                        </>
                                        :
                                        <p style={{textAlign:'center'}}>Er is geen DOT informatie bekend voor week {week}</p>
                                    }
                                    
                                    </>
                                }
                                <img src={iconTranspas} className="logo-transpas" />
                            </div>
                            :
                            null
                        }
                        {
                               !loadingDotInfo ?
                               <div className="contacts-transpas">
                                   <div className="contacts-transpas-content">
                                       <h4>Contactpersonen Transpas</h4>
                                       {
                                           contacts.length > 0 ?
                                           <>
                                           {
                                               contacts.map((item, index) =>  {
                                                   return <p>{item.EMail}</p>
                                               })
                                           }
                                           </>
                                           :
                                           <p>Er zijn geen contactpersonen in Transpas gevonden...</p>
                                       }
                                   </div>
                                   <img src={iconTranspas} className="logo-transpas" />
                               </div>
                               :
                               null
                        }

                        {
                            medewerker.gebruiker.role.type === 'super_admin' ?
                            <>
                            {
                            !isSendingDot ?
                                <input type="submit" value={'Verstuur DOT'} className="submit-form" disabled={loadingDotInfo} />
                                :
                                <Loader />
                            }
                            </>
                            :
                            null
                        }

                        
                    </form>
                    </>
                }
                
                <div id="mail-timeline">
                    {
                        debiteurInfo.fields.logs.length > 0 ?
                        <Timeline>
                            {
                                debiteurInfo.fields.logs.map((item, index) =>  {
                                    const datum_tijd = moment(item.datum_tijd);
                                    const fourteenDaysAgo = moment().subtract(14, 'days');
                                    const isOlderThanFourteenDays = datum_tijd.isBefore(fourteenDaysAgo);
                                    return  <Timeline.Item key={index}>
                                                <span>{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}
                                                {/* {
                                                    item.loadingMailerSend ?
                                                    <Loader className="mailersend-info" />
                                                    :
                                                    <>
                                                    {
                                                        isOlderThanFourteenDays ?
                                                        <>
                                                        <Icon.InfoCircle id={'mailinfo-disabled-' + index} className="mailersend-info-disabled" color="#FF6464" />
                                                        <Tooltip
                                                            anchorId={'mailinfo-disabled-' + index}
                                                            place="top"
                                                            content="Mail informatie ouder dan 14 dagen is niet beschikbaar"
                                                        />
                                                        </>
                                                        :
                                                        <Icon.InfoCircle id={'mailinfo-' + index} className="mailersend-info" color="#00D100" onClick={() => getMailerSendInfo(index)} />
                                                    }
                                                    </>
                                                } */}
                                                </span>
                                                {/* {
                                                    item.statusMailerSend.status ?
                                                    <div className="mailersend-block">
                                                        <span className="subject">{item.statusMailerSend.subject}</span>
                                                        <span className="status">{item.statusMailerSend.status}</span>
                                                        <img src={mailerSendImage} />
                                                    </div>
                                                    :
                                                    null
                                                } */}
                                                <p>{item.log_omschrijving}</p>
                                            </Timeline.Item>
                                })
                            }
                        </Timeline>
                        :
                        <p>Er zijn nog geen mail logs...</p>
                    }
                </div>
                
            </Drawer.Body>
        </Drawer>

        <Drawer size={'lg'} open={openDrawerMailerSend} onClose={() => setOpenDrawerMailerSend(false)}>
            <Drawer.Header>
                <Drawer.Title>{'Mailersend logs'}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <form id="get-mailersend" className="drawer-form" onSubmit={getMailerSendLogs}>
                    <div className="two-column">
                        <div className="field">
                            <label>Datum van</label>
                            <input type="date" placeholder="Datum van" value={fromDate} className={'form-field'} onChange={(e) => setFromDate(e.target.value)} required />
                        </div>
                        <div className="field">
                            <label>Datum tot</label>
                            <input type="date" placeholder="Datum tot" value={toDate} className={'form-field'} onChange={(e) => setToDate(e.target.value)} required />
                        </div>
                    </div>
                    <input type="submit" value="Logs ophalen" className="submit-form" />
                </form>
                {
                    mailLogs.length > 0 ?
                    <div className="list-mails">
                        {
                            mailLogs.map((item, index) =>  {
                                return <div key={index} className="mail-log-item">
                                    <div className="log-item-meta">
                                        <span>{item.email.subject}</span>
                                        <p style={{color:'#1C81C1'}}>{item.email.recipient.email}</p>
                                    </div>
                                    <div className="status">
                                        <p>{item.type}</p>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    :
                    <p>Geen mail logs gevonden...</p>
                }
            </Drawer.Body>
        </Drawer>

        {
            medewerker ?
            <BottomNav page="/debiteuren" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Debiteuren;