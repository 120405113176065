import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { TailSpin } from  'react-loader-spinner';
import DataTable from 'react-data-table-component';
import { saveAs } from "file-saver";
import Rodal from 'rodal';
import YouTube from 'react-youtube';
import PulseDot from 'react-pulse-dot';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './controleren.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

//Inline components
const Actions = (props) => {
    return (
        <div className="actions">
            {
                props.extension ?
                <button onClick={() => { saveAs(props.url, 'declaratie-' + props.fileName + '-' + props.date) }} className="red-button button"><Icon.Download /><span>Download</span></button>
                :
                null
            }
        
            <button onClick={() => {
                props.setModalStatus(props.status);
                props.setModalBedrag(props.bedrag);
                props.setModalTitle(props.omschrijving);
                props.setModalFileUrl(props.url);
                props.setModalFileName('declaratie-' + props.fileName + '-' + props.date);
                props.setModalImage(props.image);
                props.setModalDate(props.date);
                props.setModalName(props.name);
                props.setModalRitInfo(props.ritInfo)
                props.setModalLogs(props.logs);
                props.setModalExtension(props.extension);
                props.setModalId(props.id);
                props.setShowModal(true);
            }} className="red-button button"><Icon.InfoCircle /><span>Meer info</span></button>
        </div>
    )
};
const Akkoord = (props) => <button id={props.id} onClick={() => { props.updateDeclaratie(props.id, 'goedgekeurd'); }} className="confirm button" disabled={props.isLoading}><Icon.CheckLg /><span>Akkoord</span></button>;
const Afgekeurd = (props) => <button id={props.id} onClick={() => { props.redenAfgekeurd ? props.updateDeclaratie(props.id, 'afgekeurd') : props.setShowModalAfgekeurd(true); props.setModalId(props.id); }} className="decline button" disabled={props.isLoading}><Icon.XCircle /><span>Afkeuren</span></button>;
const Bedrag = (props) => <div><span className="price-number">{props.number}</span><p>&euro; {props.bedrag}</p></div>;
const Datum = (props) => <div><span className="sort sort-date">{props.sortDate}</span><p>{props.datum}</p></div>

const DeclaratiesControleren = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const videotutorials = JSON.parse(localStorage.getItem('videotutorials'));
    const environment = process.env.REACT_APP_APP_URL;
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [omschrijving, setOmschrijving] = useState('');
    const [datum, setDatum] = useState('');
    const [bestand, setBestand] = useState('');
    const [loadingDeclaraties, setLoadingDeclaraties] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const youtubePlayer = useRef(null);
    const opts = {
        height: '280',
        width: '500',
        playerVars: {
          autoplay: 0,
        },
    };

    //Function to do custom sort on number based bedrag instead of string
    const customBedragSort = (rowA, rowB) => {
        const a = rowA.bedrag.props.number;
        const b = rowB.bedrag.props.number;
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    //Function to do custom sort on timestamp instead of datestring
    const customDateSort = (rowA, rowB) => {
        const a = rowA.datum.props.sortDate;
        const timeStampA = new Date(a).getTime();
        const b = rowB.datum.props.sortDate;
        const timeStampB = new Date(b).getTime();
        if (timeStampA > timeStampB) {
            return 1;
        }
        if (timeStampB > timeStampA) {
            return -1;
        }
        return 0;
    };

    //Columns for datatable
    const columns = [
        {
            name: 'Bedrag',
            selector: row => row.bedrag,
            sortable: true,
            sortFunction: customBedragSort,
            hide: 'md',
        },
        {
            name: 'Medewerker',
            selector: row => row.medewerker,
            sortable: true,
        },
        {
            name: 'Datum',
            selector: row => row.datum,
            sortable: true,
            sortFunction: customDateSort
        },
        // {
        //     name: 'Omschrijving',
        //     selector: row => row.omschrijving,
        //     sortable: true,
        // },
        {
            name: 'Acties',
            selector: row => row.acties
        },
        {
            name: 'Status',
            selector: row => row.akkoord,
            sortable: true,
            hide: 'md',
        }
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };    
    const [declaraties, setDeclaraties] = useState([]);
    const [modalLogs, setModalLogs] = useState([]);
    const [modalExtension, setModalExtension] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalStatus, setModalStatus] = useState(null);
    const [modalBedrag, setModalBedrag] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [modalDate, setModalDate] = useState('');
    const [modalImage, setModalImage] = useState('');
    const [modalFileName, setModalFileName] = useState('');
    const [modalFileUrl, setModalFileUrl] = useState('');
    const [modalName, setModalName] = useState('');
    const [modalRitInfo, setModalRitInfo] = useState();
    const [modalId, setModalId] = useState(0);
    const [showModalAfgekeurd, setShowModalAfgekeurd] = useState(false);
    const [redenAfgekeurd, setRedenAfgekeurd] = useState('');

    //Function to get declaraties
    const getDeclaraties = async () => {
        setLoadingDeclaraties(true);
        try {
            const response = await api.get('/declaraties');
            if (response.data) {
                var arrayData = [];
                response.data.map((item, index) =>  {
                    const controleur = item.medewerker.controleur;
                    const controleurTwee = item.medewerker.controleur_twee;
                    const status = item.status;
                    if (status === 'goedgekeurd' && controleurTwee.id !== medewerker.id ) {
                        // if (item.medewerker.controleur_twee.controle_naar_vervangende_controleur === false) {
                        //     var awaitingPerson = 'Ter controle bij ' + controleurTwee.voornaam;
                        // } else {
                        //     if (item.medewerker.controleur_twee.vervangende_controleur.id !== medewerker.id) {
                        //         var awaitingPerson = 'Ter controle bij ' + controleurTwee.voornaam;
                        //     }
                        // }
                        var awaitingPerson = 'Ter controle bij ' + controleurTwee.voornaam;
                    }
                    if (status === 'goedgekeurd_def') {
                        var awaitingPerson = 'Goedgekeurd voor verwerking';
                    }
                    if (status === 'afgekeurd') {
                        var awaitingPerson = 'Afgekeurd';
                    }

                    const bedrag = Number(item.bedrag).toFixed(2);

                    const obj = {
                        id: item.id,
                        bedrag: <Bedrag 
                            bedrag={bedrag.replace(".", ",")}
                            number={item.bedrag}
                        />,
                        medewerker: item.medewerker.voornaam + ' ' + item.medewerker.achternaam,
                        datum: <Datum 
                            sortDate={item.datum}
                            datum={moment(item.datum).format('DD-MM-YYYY')}
                        />,
                        omschrijving: item.omschrijving,
                        acties: <Actions
                                    id={item.id}
                                    setModalExtension={setModalExtension}
                                    extension={item.bestand ? item.bestand.ext : ''}
                                    setModalFileName={setModalFileName}
                                    setModalFileUrl={setModalFileUrl}
                                    url={item.bestand ? environment + item.bestand.url : ''}
                                    setShowModal={setShowModal} 
                                    setModalStatus={setModalStatus}
                                    status={awaitingPerson}
                                    setModalBedrag={setModalBedrag}
                                    bedrag={'€ ' + bedrag.replace(".", ",")}
                                    setModalTitle={setModalTitle} 
                                    omschrijving={item.omschrijving} 
                                    setModalImage={setModalImage} 
                                    image={item.bestand ? environment + item.bestand.url : ''} 
                                    setModalDate={setModalDate} 
                                    date={moment(item.datum).format('DD-MM-YYYY')}
                                    setModalName={setModalName}
                                    name={item.medewerker.voornaam + ' ' + item.medewerker.achternaam}
                                    fileName={item.medewerker.voornaam + '-' + item.medewerker.achternaam}
                                    ritInfo={item.kilometer_declaratie ? [item.van, item.naar, item.kilometers, item.retour] : ''}
                                    setModalRitInfo={setModalRitInfo}
                                    setModalLogs={setModalLogs}
                                    logs={item.logs}
                                    setModalId={setModalId}
                                />,
                        akkoord: awaitingPerson ? 
                        awaitingPerson 
                        :
                        <>
                        {
                            item.status === 'afgekeurd' ?
                            <p>Afgekeurd</p>
                            :
                            <div className="buttons">
                                <Akkoord id={item.id} updateDeclaratie={updateDeclaratie} isLoading={isLoading} />
                                <Afgekeurd id={item.id} updateDeclaratie={updateDeclaratie} isLoading={isLoading} redenAfgekeurd={redenAfgekeurd} setShowModalAfgekeurd={setShowModalAfgekeurd} setModalId={setModalId} />
                            </div>
                        }
                        </>
                    };
                    arrayData.push(obj);
                })
                setDeclaraties(arrayData);
                setLoadingDeclaraties(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }

    //Function to update declaratie
    const updateDeclaratie = async (id, status) => {
        if (status === 'afgekeurd' && !redenAfgekeurd) {
            notifyError('Vul een opmerking waarom deze is afgekeurd in');
            return;
        }
        setIsLoading(true);
        try {
            const response = await toast.promise(
                api.put('/declaraties/:id', {
                    "data" : {
                        "id": id,
                        "status": status,
                        "reden_afgekeurd": redenAfgekeurd ? redenAfgekeurd : ""
                    }
                }),
                {
                    loading: 'Declaratie bijwerken...',
                    success: <b>Declaratie is bijgewerkt</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setShowModal(false);
                setShowModalAfgekeurd(false);
                setIsLoading(false);
                getDeclaraties();
            }
        } catch (error) {}
    }
    
    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (medewerker.gebruiker.role.type !== 'controleur' && medewerker.gebruiker.role.type !== 'wagenpark_en_controleur' && medewerker.gebruiker.role.type !== 'loonadministratie' && medewerker.gebruiker.role.type !== 'super_admin') {
            if (medewerker.is_vervanger === false) {
                navigate('/declareren');
            }
        }
        getDeclaraties();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/declaraties-controleren" />
        <div className="dashboard-content">
            <DashboardHeader title="Declaraties controleren" />
            {
                videotutorials.declaraties_controleren ?
                <button className="help-button" onClick={() => setShowModalHelp(true)}><Icon.QuestionCircle /><span>Help</span></button>
                :
                null
            }
            {
                loadingDeclaraties ?
                <>
                <Skeleton height={40} style={{marginBottom:10,marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                {
                    declaraties.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={declaraties}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        fixedHeader
                    />
                    :
                    <p>Er zijn geen declaraties gevonden.</p>
                }
                </>
            }
            <Rodal visible={showModal} onClose={() => { setShowModal(false) }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <div className="modal-header"><p className="title">{modalBedrag}<span className="date">{modalDate}</span></p></div>
                <div className="modal-content">
                    {
                        modalExtension !== '.pdf' ?
                        <img src={modalImage} />
                        :
                        <button onClick={() => { saveAs(modalFileUrl, modalFileName) }} className="download-pdf"><Icon.FiletypePdf /><span>Download</span></button>
                    }
                    {
                        modalRitInfo ?
                        <div className="rit-details">
                            <div className="column">
                                <p className="label">Van</p>
                                <p className="rit-value">{modalRitInfo[0]}</p>
                            </div>
                            <div className="column">
                                <p className="label">Naar</p>
                                <p className="rit-value">{modalRitInfo[1]}</p>
                            </div>
                            <div className="column">
                                <p className="label">Aantal kilometer</p>
                                <p className="rit-value">{modalRitInfo[2]}</p>
                            </div>
                            <div className="column">
                                <p className="label">Retour?</p>
                                <p className="rit-value">{modalRitInfo[3] ? 'Ja' : 'Nee'}</p>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="modal-logs">
                    
                    <table id="logs">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Door</th>
                                <th>Wanneer</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            modalLogs.map((item, index) =>  {
                                return (
                                    <tr key={index} className="log">
                                        <td>{item.log_omschrijving} {item.toelichting ? <><br/><span>{item.toelichting}</span></> : null}</td>
                                        <td>{item.medewerker.voornaam + ' ' + item.medewerker.achternaam}</td>
                                        <td>{moment(item.datum_tijd).format('DD-MM-YYYY HH:mm')}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>

                </div>
                <div className="modal-footer">
                    <div className="modal-info"><p>{modalName}</p><p className="modal-omschrijving">{modalTitle}</p></div>
                    {
                        modalStatus ?
                        <p className="status-name"><PulseDot color='info' style={{ fontSize: 10 }} /> {modalStatus}</p>
                        :
                        <div className="buttons">
                            <Afgekeurd id={modalId} updateDeclaratie={updateDeclaratie} redenAfgekeurd={redenAfgekeurd} setShowModalAfgekeurd={setShowModalAfgekeurd} setModalId={setModalId} />
                            <Akkoord id={modalId} updateDeclaratie={updateDeclaratie} />
                        </div>
                    }
                   
                </div>
            </Rodal>


            <Rodal visible={showModalAfgekeurd} onClose={() => { setShowModalAfgekeurd(false); setRedenAfgekeurd('') }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <div className="modal-header"><p className="title">Waarom keur je deze declaratie af?</p></div>
                <form id="declined" className="declined-form" onSubmit={(e) => { e.preventDefault(); updateDeclaratie(modalId, 'afgekeurd') }}>
                <textarea name="reden_afgekeurd" placeholder="Reden van afkeuring" value={redenAfgekeurd} onChange={(e) => setRedenAfgekeurd(e.target.value)} className="form-field" />
                <div className="form-footer">
                    <input type="submit" value="Opslaan" className="submit-form" disabled={isLoading} />
                </div>
                </form>
            </Rodal>

            {/* <Rodal visible={showModalHelp} onClose={() => { setShowModalHelp(false); youtubePlayer.current.internalPlayer.pauseVideo(); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3>Uitleg declaraties controleren</h3>
                <YouTube videoId={videotutorials.declaraties_controleren} opts={opts} ref={youtubePlayer} />
            </Rodal> */}

            <Toaster />
        </div>
        <BottomNav page="/declaraties-controleren" />
        <img src={vsdvArtwork} className="artwork" />
    </div>
    );
};
export default DeclaratiesControleren;