import { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import DataTable from 'react-data-table-component';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './prikbord.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

//Inline components
const Actions = (props) => {
    return (
        <div className="actions">
            <button onClick={() => props.navigate('/prikbord/' + props.uuid)} className="red-button button view"><span>Bekijken</span></button>
            {
                props.gelezen_begrepen ?
                <p>Gelezen en begrepen op {moment(props.gelezen_begrepen).format('DD-MM-YYYY HH:mm')}</p>
                :
                null
            }
        </div>
    )
};
const Datum = (props) => <div><span className="sort sort-date">{props.sortDate}</span><p>{props.datum}</p></div>

const Prikbord = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const environment = process.env.REACT_APP_APP_URL;
    const [loadingPrikbord, setLoadingPrikbord] = useState(true);
    const [prikbordItems, setPrikbordItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    //Function to do custom sort on timestamp instead of datestring
    const customDateSort = (rowA, rowB) => {
        const a = rowA.datum.props.sortDate;
        const timeStampA = new Date(a).getTime();
        const b = rowB.datum.props.sortDate;
        const timeStampB = new Date(b).getTime();
        if (timeStampA > timeStampB) {
            return 1;
        }
        if (timeStampB > timeStampA) {
            return -1;
        }
        return 0;
    };

    //Columns for datatable
    const columns = [
        {
            name: 'Titel',
            selector: row => row.titel,
            sortable: true,
            hide: 'md',
        },
        {
            name: 'Datum',
            selector: row => row.datum,
            sortable: true,
            sortFunction: customDateSort
        },
        {
            name: 'Aangemaakt door',
            selector: row => row.aangemaakt_door,
            sortable: true,
        },
        {
            name: 'Acties',
            selector: row => row.acties
        }
    ];

    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };    

    //Function to get declaraties
    const getPrikbordItems = async () => {
        setLoadingPrikbord(true);
        try {
            const response = await api.get('/prikborden');
            if (response.data) {
                var arrayData = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        uuid: item.uuid,
                        titel: item.titel,
                        aangemaakt_door: item.aangemaakt_door,
                        datum: <Datum 
                            sortDate={item.datum}
                            datum={moment(item.datum).format('DD-MM-YYYY')}
                        />,
                        acties: <Actions uuid={item.uuid} gelezen_begrepen={item.gelezen_begrepen} navigate={navigate} />,
                    };
                    arrayData.push(obj);
                })
                setPrikbordItems(arrayData);
                setLoadingPrikbord(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }
    
    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getPrikbordItems();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/prikbord" />
        <div className="dashboard-content">
            <DashboardHeader title="Prikbord" />
            {
                loadingPrikbord ?
                <>
                <Skeleton height={40} style={{marginBottom:10,marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                {
                    prikbordItems.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={prikbordItems}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={50}
                        fixedHeader
                    />
                    :
                    <p>Er zijn geen prikbord items gevonden...</p>
                }
                </>
            }
            <Toaster />
        </div>
        <BottomNav page="/prikbord" />
        <img src={vsdvArtwork} className="artwork" />
    </div>
    );
};
export default Prikbord;