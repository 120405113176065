import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import { Tooltip } from 'react-tooltip';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './wagenpark.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

//Inline components
const Datum = (props) => <div><p>{props.datum}</p></div>
const Kenteken = (props) => <div><p>{props.kenteken}</p></div>
const Actions = (props) => <div className="actions schade-actions">
    <button onClick={() => props.navigate('/wagenpark/werkbon/' + props.item.id)} className="red-button button view-dossier">Bekijken</button>
    {
        props.item.status.key === 'in_afwachting_wagenpark' ?
        <>
            <Icon.Link45deg size={20} id={'create-link-' + props.item.id} className="edit-button" onClick={() => { props.updateStatus(props.item, {value: 10, label: 'In afwachting reparateur'}) }} />
            <Tooltip
                anchorId={'create-link-' + props.item.id}
                place="top"
                content="Creëer link voor externe reparateur"
            />
        </>
        :
        null
    }
    {
        props.item.status.key === 'in_afwachting_reparateur' ?
        <>
            <Icon.Clipboard size={20} id={'copy-werkbon-link-' + props.item.id} className="edit-button" onClick={() => { notifySuccess('Link naar werkbon aanmaken gekopieërd'); navigator.clipboard.writeText('https://personeel.vsdv.nl/werkbon-reparateur/' + props.item.uuid);}} />
            <Tooltip
                anchorId={'copy-werkbon-link-' + props.item.id}
                place="top"
                content="Link naar werkbon aanmaken kopiëren"
            />
        </>
        :
        null
    }
    {
                props.indexWerkbon > -1 ?
                <>
                <Icon.FileEarmarkPdf size={20} id={'show-pdf-' + props.item.id} className="edit-button" onClick={() => { props.getFileContents(props.item.kenmerk, 'Documenten', props.item.documenten[props.indexWerkbon].pad) }} />
                <Tooltip
                    anchorId={'show-pdf-' + props.item.id}
                    place="top"
                    content="Toon PDF taxatie externe reparateur"
                />
                </>
                :
                null
            }
    {
        props.item.status.key === 'in_afwachting_wagenpark' && (props.item.vorige_status ? props.item.vorige_status.key === 'in_afwachting_reparateur' : null) ?
        <>
            <Icon.CheckCircleFill size={20} color={'#00D100'} id={'check-' + props.item.id} className="edit-button" onClick={() => { props.updateStatus(props.item, {value: 6, label: 'Beoordeeld door wagenpark'}) }} />
            <Tooltip
                anchorId={'check-' + props.item.id}
                place="top"
                content="Externe taxatie akkoord, zet in schadedossier"
            />
            {/* {
                props.indexWerkbon > -1 ?
                <>
                <Icon.FileEarmarkPdf size={20} id={'show-pdf-' + props.item.id} className="edit-button" onClick={() => { props.fetchPdf(props.environment + '' + props.item.documenten[props.indexWerkbon].document.url) }} />
                <Tooltip
                    anchorId={'show-pdf-' + props.item.id}
                    place="top"
                    content="Toon PDF taxatie externe reparateur"
                />
                </>
                :
                null
            } */}
            {/* {
                props.indexWerkbon > -1 ?
                <>
                <Icon.FileEarmarkPdf size={20} id={'show-pdf-' + props.item.id} className="edit-button" onClick={() => { props.getFileContents(props.item.kenmerk, 'Documenten', props.item.documenten[props.indexWerkbon].pad) }} />
                <Tooltip
                    anchorId={'show-pdf-' + props.item.id}
                    place="top"
                    content="Toon PDF taxatie externe reparateur"
                />
                </>
                :
                null
            } */}
        </>
        :
        null
    }
</div>

const Wagenpark = () => {

    //Set variables
    const navigate = useNavigate();
    const environment = process.env.REACT_APP_APP_URL;
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [schades, setSchades] = useState([]);
    const [loadingSchades, setLoadingSchades] = useState(true);
    const [documentTitle, setDocumenTitle] = useState('Taxatie');
    const [showDocument, setShowDocument] = useState(false);
    const [iframePdf, setIframePdf] = useState('');
    const [filterText, setFilterText] = useState('');

    //Function to do custom sort on timestamp instead of datestring
    const customDateSort = (rowA, rowB) => {
        const a = rowA.datum.props.sortDate;
        const timeStampA = new Date(a).getTime();
        const b = rowB.datum.props.sortDate;
        const timeStampB = new Date(b).getTime();
        if (timeStampA > timeStampB) {
            return 1;
        }
        if (timeStampB > timeStampA) {
            return -1;
        }
        return 0;
    };

    //Function to sort on string value
    const filteredData = schades.filter((item) => {
        const columnsToSearch = ['kenmerk', 'verhaal_eigen', 'kenteken', 'kenteken_trailer_aanhanger', 'datum']; // Specify the columns you want to include in the search
        for (let i = 0; i < columnsToSearch.length; i++) {
          const column = columnsToSearch[i];
          if (column === 'datum') {
            var cellData = item[column].props.datum.toLowerCase();
          } else if (column === 'kenteken') {
            var cellData = item[column].props.kenteken.toLowerCase();
          } else {
            var cellData = item[column].toString().toLowerCase();
          }
          if (cellData.includes(filterText.toLowerCase())) {
            return true; // Return true if any column matches the search text
          }
        }
        return false; // Return false if no columns match the search text
    });

    //Columns for datatable
    const columns = [
        {
            name: 'Kenteken',
            selector: row => row.kenteken,
            sortable: true,
            hide: 'md',
            conditionalCellStyles: [
                {
                    when: (row) => row.updated_days_ago > 7,
                    style: {
                        borderLeft: '5px solid #cb0a32',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    },
                },
                {
                    when: (row) => row.updated_days_ago > 3 && row.updated_days_ago < 8,
                    style: {
                        borderLeft: '5px solid #ffa500',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    },
                },
                {
                    when: (row) => row.updated_days_ago < 4,
                    style: {
                        borderLeft: '5px solid #ededed',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px'
                    },
                },
            ],
        },
        {
            name: 'Kenteken trailer/aanhanger',
            selector: row => row.kenteken_trailer_aanhanger,
            sortable: true,
            hide: 'md'
        },
        {
            name: 'Kenmerk',
            selector: row => row.kenmerk,
            sortable: true,
        },
        {
            name: 'Verhaal/eigen schade',
            selector: row => row.verhaal_eigen,
            sortable: true,
        },
        {
            name: 'Datum',
            selector: row => row.datum,
            sortable: true,
            sortFunction: customDateSort
        },
        {
            name: 'Acties',
            selector: row => row.acties,
        }
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };

    const fetchPdf = async (pdfFileUrl) => {
        if (!pdfFileUrl) {
            return setIframePdf('');
        }
        const response = await fetch(pdfFileUrl);
        const data = await response.blob();
        setIframePdf(URL.createObjectURL(data));
        setShowDocument(true);
    };

    const getFileContents = async (folder, subfolder, filename) => {
        if (!filename) {
            return setIframePdf('');
        }
        api.get('/schades/get-file/' + folder + '/' + subfolder + '/' + filename, { responseType: 'blob' })
          .then(response => {
            const url = URL.createObjectURL(response.data);
            setIframePdf(url);
            setShowDocument(true);
          })
          .catch(error => {
            console.error('There was a problem with the axios request:', error);
          });
    }

    //Function to get schades
    const getSchades = async () => {
        setLoadingSchades(true);
        try {
            const response = await api.get('/schades/wagenpark');
            if (response.data) {
                var arrayData = [];
                response.data.map((item, index) =>  {
                    const diffInDays = moment().diff(item.updatedAt, 'days');
                    const indexWerkbon = item.documenten.findIndex(item => item.werkbon === true);
                    const obj = {
                        id: item.id,
                        kenmerk: item.kenmerk,
                        verhaal_eigen: item.verhaal_eigen ? item.verhaal_eigen === 'verhaal' ? 'Verhaal' : 'Eigen' : '',
                        datum: <Datum 
                            sortDate={item.datum}
                            datum={moment(item.datum).format('DD-MM-YYYY')}
                        />,
                        kenteken: <Kenteken 
                            sortKenteken={item.kenteken? item.kenteken.replace(/-/g, "") : ''}
                            kenteken={item.kenteken ? item.kenteken : ''}
                        />,
                        kenteken_trailer_aanhanger: <Kenteken 
                            sortKenteken={item.kenteken_trailer_aanhanger? item.kenteken_trailer_aanhanger.replace(/-/g, "") : ''}
                            kenteken={item.kenteken_trailer_aanhanger ? item.kenteken_trailer_aanhanger : ''}
                        />,
                        acties: <Actions item={item} navigate={navigate} updateStatus={updateStatus} fetchPdf={fetchPdf} getFileContents={getFileContents} setDocumenTitle={setDocumenTitle} environment={environment} indexWerkbon={indexWerkbon} />,
                        updated_days_ago: diffInDays
                    };
                    arrayData.push(obj);
                })
                setSchades(arrayData);
                setLoadingSchades(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
        }
    }

    const updateStatus = async (schade, status) => {
        try {
            const response = await toast.promise(
                api.post('/schades/status/' + schade.id, {
                    data: {
                        status: status,
                        vorige_status: schade.status.id
                    }
                }),
                {
                    loading: 'Opslaan...',
                    success: <b>Schadestatus is bijgewerkt</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                getSchades();
            }
        } catch (error) {}
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getSchades();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/wagenpark" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Wagenpark" />
            <div className="controleren-header schadeoverzicht">
                <div className="legenda">
                    <div className="legenda-item">
                        <div className="legenda-color orange"></div>
                        <p>Laatste update 3 tot 7 dagen geleden</p>
                    </div>
                    <div className="legenda-item">
                        <div className="legenda-color red"></div>
                        <p>Laatste update langer dan 7 dagen geleden</p>
                    </div>
                </div>
                <div className="schade-actions">
                    <input
                        type="text"
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                        placeholder="Zoeken..."
                    />
                </div>
            </div>

            {
                loadingSchades ?
                <>
                <Skeleton height={40} style={{marginBottom:10,marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                {
                    schades.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        fixedHeader
                    />
                    :
                    <p>Er zijn geen schades gevonden.</p>
                }
                </>
            }
            <div className={'view-document side-preview ' + (showDocument ? 'show' : '')}>
                <div className="side-preview-header">
                    <h3>{documentTitle}</h3>
                    <Icon.XCircleFill onClick={() => setShowDocument(false)} />
                </div>
                <div className="side-preview-content">
                    {
                        iframePdf ?
                        <iframe src={`${iframePdf}#view=fitH`} title="view PDF" height="100%" width="100%" />
                        :
                        null
                    }
                </div>
            </div>
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/wagenpark" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Wagenpark;