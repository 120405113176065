import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import { TailSpin } from  'react-loader-spinner';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './instellingen.css';

const Instellingen = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [settings, setSettings] = useState([]);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [notificationAbove, setNotifcationAbove] = useState('');
    const [maxRows, setMaxRows] = useState('');
    const [longBreak, setLongBreak] = useState('');
    const [sendReminders, setSendReminders] = useState(false);
    const [rekenfactor, setRekenfactor] = useState('');
    const [vervangendeControleur, setVervangendeControleur] = useState('');
    const [vervangerActive, setVervangerActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    //Initialize notify for error and success
    const notifyError = (text) => toast.error(text, {
        position: "top-center"
    });
    const notifySuccess = (text) => toast.success(text, {
        position: "top-center"
    });

    //Function to update password
    const handlePassword = async (event) => {
        event.preventDefault();
        if (!currentPassword) {
            notifyError('Vul je huidige wachtwoord in.');
            return;
        }
        if (!newPassword) {
            notifyError('Vul een nieuw wachtwoord in.');
            return;
        }
        if (!confirmNewPassword) {
            notifyError('Bevestig het nieuwe wachtwoord.');
            return;
        }
        if (newPassword !== confirmNewPassword) {
            notifyError('De wachtwoorden komen niet overeen.');
            return;
        }
        if (currentPassword === newPassword) {
            notifyError('Je nieuwe wachtwoord mag niet hetzelfde zijn als je huidige wachtwoord.');
            return;
        }
        setIsLoading(true);
        try {
            const response = await api.post('/auth/change-password', {
                "currentPassword": currentPassword,
                "password": newPassword,
                "passwordConfirmation": confirmNewPassword
            });
            if (response.data) {
                setCurrentPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                notifySuccess('Je wachtwoord is bijgewerkt en opgeslagen!');
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    if (error.response.data.error.message.includes("invalid")) {
                        notifyError('Het ingevulde huidige wachtwoord is ongeldig');
                    }
                    else if (error.response.data.error.message.includes("least")) {
                        notifyError('Je nieuwe wachtwoord moet minstens 6 tekens bevatten');
                    }
                    else {
                        notifyError(error.response.data.error.message);
                    }
                    
            }
        }
    }

    const changeReminders = () => {
        if (sendReminders) {
            setSendReminders(false);
        } else {
            setSendReminders(true);
        }
    };

    //Function to update portal settings
    const handleSettings = async (event) => {
        event.preventDefault();
        try {
            const response = await toast.promise(
                api.put('/instelling/', {
                    "data" : {
                        "stuur_notificatie_boven": notificationAbove,
                        "maximum_rijen_per_dag": maxRows,
                        "lange_pauze": longBreak,
                        "verstuur_registratie_reminders": sendReminders,
                        "rekenfactor_km_declaratie": rekenfactor
                    }
                }),
                {
                    loading: 'Declaratie aanmaken...',
                    success: <b>Portal instellingen opgeslagen</b>,
                    error: <b>Er is iets misgegaan met het toevoegen. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setIsLoading(false);
            }
        } catch (error) {}
    }

    const changeVervanger = () => {
        if (vervangerActive) {
            setVervangerActive(false);
        } else {
            setVervangerActive(true);
        }
    };

    const handleControleurs = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const obj = {
            "data": {
                "controle_naar_vervangende_controleur": vervangerActive
            }
        }
        try {
            const response = await toast.promise(
                api.put('/medewerkers/' + medewerker.id, {
                    "data" : {
                        "controle_naar_vervangende_controleur": vervangerActive
                    }
                }),
                {
                    loading: 'Opslaan...',
                    success: <b>Instellingen opgeslagen</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    //Function to get medewerker info
    const getMedewerker = async () => {
        try {
            const response = await api.get('/medewerkers/:id');
            if (response.data) {
                if (response.data.vervangende_controleur) {
                    setVervangendeControleur(response.data.vervangende_controleur.voornaam + ' ' + response.data.vervangende_controleur.achternaam);
                    setVervangerActive(response.data.controle_naar_vervangende_controleur);
                }
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT.');
            }
        }
    }



    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            setSettings(result);
            setNotifcationAbove(result.stuur_notificatie_boven);
            setMaxRows(result.maximum_rijen_per_dag);
            setLongBreak(result.lange_pauze);
            setSendReminders(result.verstuur_registratie_reminders);
            setRekenfactor(result.rekenfactor_km_declaratie);
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getMedewerker();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/instellingen" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Instellingen" />
            <div className="blocks">
                <div className="block">
                    <div className="content">
                    <div className="block-header"><h3>Wachtwoord wijzigen</h3></div>
                    <form id="change-password" className="password-form" onSubmit={handlePassword}>
                        <input type="password" name="current_password" placeholder="Huidig wachtwoord" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} className="form-field" disabled={isLoading} />
                        <input type="password" name="new_password" placeholder="Nieuw wachtwoord" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} className="form-field" disabled={isLoading} />
                        <input type="password" name="confirm_new_password" placeholder="Nieuw wachtwoord herhalen" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} className="form-field" disabled={isLoading} />
                        <div className="form-footer">
                        <input type="submit" value="Bijwerken" className="submit-form" disabled={isLoading} />
                        {
                            isLoading ?
                            <TailSpin
                                height="30"
                                width="30"
                                color="#001c43"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                            />
                            :
                            null
                        }
                        </div>
                    </form>
                    </div>
                </div>

                {
                    medewerker.gebruiker.role.type === 'super_admin' ?
                    <div className="block">
                        <div className="content">
                        <div className="block-header"><h3>Instellingen portal</h3></div>
                        <form id="change-settings" className="settings-form" onSubmit={handleSettings}>
                            <div className="field">
                                <label>Stuur notificatie van declaratie bij bedrag hoger dan:</label>
                                <input type="number" name="notification_above" placeholder="100" value={notificationAbove} onChange={(e) => setNotifcationAbove(e.target.value)} className="form-field" disabled={isLoading} required />
                            </div>
                            <div className="field">
                                <label>Maximaal aantal toe te voegen rijen per dag:</label>
                                <input type="number" name="rows_per_day" placeholder="3" value={maxRows} onChange={(e) => setMaxRows(e.target.value)} className="form-field" disabled={isLoading} required />
                            </div>
                            <div className="field">
                                <label>Toon lange pauze label bij langere pauze dan x uur:</label>
                                <input type="number" name="long_break" placeholder="1" value={longBreak} onChange={(e) => setLongBreak(e.target.value)} className="form-field" disabled={isLoading} required />
                            </div>
                            <div className="field">
                                <label className="checkbox">
                                    <input type="checkbox" checked={sendReminders} onChange={changeReminders} />
                                    Verstuur reminders van registraties
                                </label>
                            </div>
                            <div className="field">
                                <label>Rekenfactor voor declareren kilometers:</label>
                                <input type="number" name="multiplier" placeholder="0.21" value={rekenfactor} onChange={(e) => setRekenfactor(e.target.value)} className="form-field" disabled={isLoading} required />
                            </div>
                            <div className="form-footer">
                            <input type="submit" value="Opslaan" className="submit-form" disabled={isLoading} />
                            {
                                isLoading ?
                                <TailSpin
                                    height="30"
                                    width="30"
                                    color="#001c43"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                null
                            }
                            </div>
                        </form>
                        </div>
                    </div>
                    :
                    null
                }
                {
                    medewerker.gebruiker.role.type === 'super_admin' || medewerker.gebruiker.role.type === 'controleur' || medewerker.gebruiker.role.type === 'loonadministratie' ?
                    <div className="block">
                        <div className="content">
                        <div className="block-header"><h3>Instellingen controleren</h3></div>
                        <form id="change-controleurs" className="settings-form" onSubmit={handleControleurs}>
                            <div className="vervangend-controleur">
                                <span>Vervangende controleur:</span>
                                <p>{vervangendeControleur}</p>
                            </div>
                            <div className="field">
                                <label className="checkbox">
                                    <input type="checkbox" checked={vervangerActive} onChange={changeVervanger} />
                                    Inschakelen
                                </label>
                            </div>
                            <div className="form-footer">
                            <input type="submit" value="Opslaan" className="submit-form" disabled={isLoading} />
                            {
                                isLoading ?
                                <TailSpin
                                    height="30"
                                    width="30"
                                    color="#001c43"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                null
                            }
                            </div>
                        </form>
                        </div>
                    </div>
                    :
                    null
                }

            </div>
            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/instellingen" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Instellingen;