import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, getVideoTutorials, notifyError, notifySuccess, getTimeFromSeconds, countTotalSeconds } from '../../../settings/Helpers';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { TailSpin } from  'react-loader-spinner';
import exportFromJSON from 'export-from-json';
import * as Sentry from "@sentry/react";

//API
import api from '../../../settings/AxiosSetup';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import Registratiedag from '../../../components/registreren/registratiedag';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';

//styles
import './dashboard.css';
import 'react-loading-skeleton/dist/skeleton.css';

const Dashboard = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const environment = process.env.REACT_APP_APP_URL;
    const [voornaam, setVoornaam] = useState('');
    const [achternaam, setAchternaam] = useState('');
    const [personeelsnummer, setPersoneelsnummer] = useState('');
    const [contracturen, setContracturen]  = useState('');
    const [loonperiode, setLoonperiode] = useState('');
    const [isControleur, setIsControleur] = useState(false);
    const [teControleren, setTeControleren] = useState([]);
    const [divisie, setDivisie] = useState('');
    const [divisies, setDivisies] = useState([]);
    const [telefoonnummer, setTelefoonnummer] = useState('');
    const [allRegistraties, setAllRegistraties] = useState([]);
    const [registraties, setRegistraties] = useState([]);
    const [cantSave, setCantSave] = useState(false);
    const [previousWeekBefore, setPreviousWeekBefore] = useState('');
    const [previousWeek, setPreviousWeek] = useState('');
    const [currentWeek, setCurrentWeek] = useState('');
    const [nextWeek, setNextWeek] = useState('');
    const [exportYearMonths, setExportYearMonths] = useState(moment().format('YYYY'));
    const [divisieExportMonth, setDivisieExportMonth] = useState(0);
    const [divisieExportMonthName, setDivisieExportMonthName] = useState('Alle divisies');
    const [exportYearWeeks, setExportYearWeeks] = useState(moment().format('YYYY'));
    const [divisieExportWeeks, setDivisieExportWeeks] = useState(0);
    const [exportStartweekLists, setExportStartweekLists] = useState('');
    const [exportEndweekLists, setExportEndweekLists] = useState('');
    const [exportDivisieLists, setExportDivisieLists] = useState(0);
    const [exportYearLists, setExportYearLists] = useState(moment().format('YYYY'));
    const [divisieExportWeeksName, setDivisieExportWeeksName] = useState('Alle divisies');
    const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
    const [weekNumber, setWeekNumber] = useState(moment().isoWeek());
    const [totalHours, setTotalHours] = useState('00:00');
    const [verlofHours, setVerlofHours] = useState('00:00');
    const [contractHours, setContractHours] = useState('00:00');
    const [urenTypes, setUrenTypes] = useState([]);
    const [loadingRegistraties, setLoadingRegistraties] = useState(false);
    const [noRegistrations, setNoRegistrations] = useState(false);
    const [maxRows, setMaxRows] = useState(0);
    const [longBreak, setLongBreak] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [exportMonth, setExportMonth] = useState(moment().subtract(1, 'months').month());
    const [errorMessageMonthExport, setErrorMessageMonthExport] = useState('');
    const [showErrorNotAll, setShowErrorNotAll] = useState(false);
    const months = [
        {
            value: 0,
            name: 'Januari'
        },
        {
            value: 1,
            name: 'Februari'
        },
        {
            value: 2,
            name: 'Maart'
        },
        {
            value: 3,
            name: 'April'
        },
        {
            value: 4,
            name: 'Mei'
        },
        {
            value: 5,
            name: 'Juni'
        },
        {
            value: 6,
            name: 'Juli'
        },
        {
            value: 7,
            name: 'Augustus'
        },
        {
            value: 8,
            name: 'September'
        },
        {
            value: 9,
            name: 'Oktober'
        },
        {
            value: 10,
            name: 'November'
        },
        {
            value: 11,
            name: 'December'
        }
    ];
    const [errorMessageWeeksExport, setErrorMessageWeeksExport] = useState('');
    const [exportFromWeek, setExportFromWeek] = useState('');
    const [exportToWeek, setExportToWeek] = useState('');
    const delay = ms => new Promise(res => setTimeout(res, ms));

    //Function to get medewerker info
    const getMedewerker = async () => {
        try {
            const response = await api.get('/medewerkers/:id');
            if (response.data) {
                setVoornaam(response.data.voornaam);
                setAchternaam(response.data.achternaam);
                setPersoneelsnummer(response.data.personeelsnummer);
                setContracturen(response.data.contracturen ? response.data.contracturen : '-');
                setLoonperiode(response.data.loonperiode === 'per_maand' ? 'Per maand' : 'Per 4 weken');
                setDivisie(response.data.divisie.naam ? response.data.divisie.naam : '-');
                setTelefoonnummer(response.data.telefoonnummer ? response.data.telefoonnummer : '-');
                if (response.data.gebruiker.role.type === 'super_admin' || response.data.gebruiker.role.type === 'controleur' || response.data.gebruiker.role.type === 'wagenpark_en_controleur' || response.data.gebruiker.role.type === 'loonadministratie') {
                    //isControleur(true);
                    getTeControleren();
                }
                localStorage.setItem("medewerker", JSON.stringify(response.data));
                Sentry.setUser({
                    fullName: response.data.voornaam + ' ' + response.data.achternaam,
                    email: response.data.emailadres,
                });
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    localStorage.removeItem('bearerToken');
                    localStorage.removeItem('ingelogd');
                    localStorage.removeItem('medewerker');
                    localStorage.removeItem('videotutorials');
                    navigate('/login');
                    //notifyError('Er is iets fout gegaan (400). Probeer uit te loggen en weer in te loggen en neem anders contact op met ICT.');
                    
            }
        }
    }

    const getTeControleren = async () => {
        try {
            const response = await api.post('/registraties/toon-overzicht-weken');
            if (response.data) {
                setTeControleren(response.data);
            }
        } catch (error) {}
    }

    //Get urentypes for filling time fields
    const getUrenTypes = async () => {
        try {
            const response = await api.get('/urentypes');
            if (response.data) {
                setUrenTypes(response.data);
            }
        } catch (error) {}
    }

    //Get options while passing 2 params
    const getOptions = async (route, state) => {
        const itemsArray = [];
        try {
            const response = await api.get(route);
            if (response.data) {
                response.data.data.map((item, index) =>  {
                    const arrayItem = {
                        id: item.id,
                        name: item.attributes.naam
                    };
                    itemsArray.push(arrayItem);
                });
                state(itemsArray);
            }
        } catch (error) {}
    }
    

    //Function to set the week to view
    const setRegistratiesByWeekNumber = (arrayRegistraties, week) => {
        const result = arrayRegistraties.find(item => item.weeknummer === week);
        if (!result) {
            setRegistraties([]);
        } else {
            setRegistraties(result);
            setCurrentMonth(moment(result.maandag.datum).format('MMMM'));
        }
    }

    //Function to get registraties
    const getRegistraties = async () => {
        setLoadingRegistraties(true);
        try {
            const response = await api.get('/registraties?me=true');
            if (response.data) {
                if (response.data[0].registraties.length === 0) {
                    setNoRegistrations(true);
                    setLoadingRegistraties(false);
                    return;
                }
                const currentDayOfWeekNumber = moment().day();
                setAllRegistraties(response.data[0].registraties);
                if (currentDayOfWeekNumber === 0) {
                    setRegistratiesByWeekNumber(response.data[0].registraties, weekNumber + 1);
                } else {
                    setRegistratiesByWeekNumber(response.data[0].registraties, weekNumber);
                }

                setPreviousWeekBefore(response.data[0].registraties.find(item => item.weeknummer === (weekNumber - 2)));
                setPreviousWeek(response.data[0].registraties.find(item => item.weeknummer === (weekNumber - 1)));
                setCurrentWeek(response.data[0].registraties.find(item => item.weeknummer === weekNumber));
                setNextWeek(response.data[0].registraties.find(item => item.weeknummer === (weekNumber + 1)));

                setContractHours(response.data[0].contracturen);
                setMaxRows(response.data[0].maximum_rijen);
                setLongBreak(response.data[0].lange_pauze);
                setNoRegistrations(false);
                setLoadingRegistraties(false);
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer het later nog eens of neem contact op met ICT. Uitloggen en weer inloggen kan soms ook helpen.');
            }
        }
    }

    const saveRegistratie = async () => {
        setIsLoading(true);
        try {
            const response = await toast.promise(
                api.put('/registraties/:id', {
                    "data" : registraties
                }),
                {
                    loading: 'Opslaan...',
                    success: <b>Uren opgeslagen</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setIsLoading(false);
                setTotalHours('00:00');
            }
        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleTotalHours = (uren) => {
        var arrayDays = [uren.maandag, uren.dinsdag, uren.woensdag, uren.donderdag, uren.vrijdag, uren.zaterdag, uren.zondag];
        var seconds = 0;
        arrayDays.map((item, index) =>  {
            seconds += countTotalSeconds(item);
        });
        setTotalHours(getTimeFromSeconds(seconds));
        if (getTimeFromSeconds(seconds).includes("NaN") ||  getTimeFromSeconds(seconds) === '00:00') {
            setCantSave(true);
        } else {
            setCantSave(false);
        }
        
    }

    const showStatus = (status) => {
        if (status === 'open') {
            return <p className="pending">Open</p>
        } else if (status === 'deels_ingestuurd') {
            return <p className="correct">Deels ingestuurd</p>
        } else if (status === 'deels_goedgekeurd') {
            return <p className="correct">Deels goedgekeurd</p>
        } else if (status === 'goedgekeurd_def') {
            return <p className="correct">Definitief goedgekeurd</p>
        } else if (status === 'deels_goedgekeurd_def') {
            return <p className="correct">Deels definitief goedgekeurd</p>
        } else if (status === 'deels_verwerkt') {
            return <p className="correct">Deels verwerkt</p>
        } else {
            return <p className="correct">{status}</p>
        }
    }

    const downloadCsv = (data, type) => {
        if (type === 'maand') {
            var fileName = 'export-uren-' + divisieExportMonthName + '-' + moment().month(exportMonth).format('MMMM') + '-' + exportYearMonths + '-' + moment().format('HH:mm:ss');
        } else {
            var fileName = 'export-uren-week-' + divisieExportWeeksName + '-' + exportFromWeek + '-tot-' + exportToWeek + '-' + exportYearWeeks + '-' + moment().format('HH:mm:ss');
        }
        const exportType =  exportFromJSON.types.csv
        exportFromJSON({ data, fileName, exportType })
        setIsLoading(false);
    }

    const exportMonthRegistrations = async (event) => {
        event.preventDefault();
        setErrorMessageMonthExport('');
        setErrorMessageWeeksExport('');
        setIsLoading(true);
        setShowErrorNotAll(false);
        const obj = {
            "data": {
                "divisie": Number(divisieExportMonth),
                "maand": exportMonth,
                "jaar": exportYearMonths
            }
        };
        try {
            const response = await api.post('/registraties/export', {
                "data": {
                    "divisie": Number(divisieExportMonth),
                    "maand": exportMonth,
                    "jaar": exportYearMonths
                }
            });
            if (response.status === 204) {
                setShowErrorNotAll(true);
            }
            if (response.data) {
                downloadCsv(response.data, 'maand');
            }
        } catch (error) {
            console.log(error);
            setErrorMessageMonthExport(error.response.data.error.details.message);
            setIsLoading(false);
        }
    }

    const exportWeekRegistrations = async (event) => {
        event.preventDefault();
        if (exportFromWeek >= exportToWeek) {
            notifyError('Startweek moet lager zijn dan eindweek');
            return;
        }
        setErrorMessageMonthExport('');
        setErrorMessageWeeksExport('');
        setIsLoading(true);
        try {
            const response = await api.post('/registraties/export', {
                "data": {
                    "divisie": Number(divisieExportWeeks),
                    "startweek": exportFromWeek,
                    "endweek": exportToWeek,
                    "jaar": exportYearWeeks
                }
            });
            if (response.data) {
                downloadCsv(response.data, 'weken');
            }
        } catch (error) {
            setErrorMessageWeeksExport(error.response.data.error.details.message);
            setIsLoading(false);
        }
    }

    const removeExports = async () => {
        try {
            const response = await api.post('/registraties/verwijder-exports');
            if (response.data) {}
        } catch (error) {}
    }

    const downloadZipFile = async (fileName) => {
        await delay(1000);
        try {
          const response = await api.get(environment + '/uploads/' + fileName, {
            responseType: 'blob',
          });
    
          const blob = new Blob([response.data], { type: 'application/zip' });
    
          // Create a URL for the Blob and trigger download
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = fileName;
          link.click();
    
          // Clean up the URL object after the download
          window.URL.revokeObjectURL(downloadUrl);
          removeExports();
        } catch (error) {
          console.error('Error fetching or processing the zip file:', error);
        }
    };


    const createExcels = async (event) => {
        event.preventDefault();
        if (exportStartweekLists >= exportEndweekLists) {
            notifyError('Startweek moet lager zijn dan eindweek');
            return;
        }
        console.log({
            "startweek": exportStartweekLists,
            "endweek": exportEndweekLists,
            "divisie": exportDivisieLists,
            "jaar": exportYearLists
        });
        try {
            const response = await api.post('/registraties/export-urenstaten', {
                "data": {
                    "startweek": exportStartweekLists,
                    "endweek": exportEndweekLists,
                    "divisie": exportDivisieLists,
                    "jaar": exportYearLists
                }
            });
            console.log(response);
            if (response.data) {
                downloadZipFile(response.data);
            }
        } catch (error) {}
    }
    

    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        const videotutorials = getVideoTutorials();
        videotutorials.then((result) => {
            localStorage.setItem("videotutorials", JSON.stringify(result));
        });
        getMedewerker();
        getRegistraties();
        getUrenTypes();
        getOptions('/divisies', setDivisies);
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/" />
            :
            null
        }
        
        <div className="dashboard-content">
            {
                !voornaam ?
                <Skeleton height={40} width={'50%'} style={{marginBottom:20}} />
                :
                <DashboardHeader voornaam={voornaam} />
            }

            <div className="hours-today">
                <h2>Uren vandaag registreren</h2>
                {
                    registraties && Object.keys(registraties).length ?
                    <>
                    <Registratiedag 
                        day={moment().format('dddd')}
                        registraties={registraties}
                        status={registraties.status}
                        currentMonth={currentMonth}
                        dayInfo={registraties[moment().format('dddd')]}
                        setAllRegistraties={setAllRegistraties}
                        allRegistraties={allRegistraties}
                        urenTypes={urenTypes}
                        weekNumber={weekNumber}
                        getTimeFromSeconds={getTimeFromSeconds}
                        handleTotalHours={handleTotalHours}
                        maxRows={maxRows}
                        longBreak={longBreak}
                        medewerker={medewerker}
                        notifyError={notifyError} 
                    />
                    <div className="footer-today">
                        <button className="save" onClick={() => saveRegistratie()} disabled={cantSave}>
                            {
                                isLoading ?
                                <TailSpin
                                    height="20"
                                    width="20"
                                    color="#cb0a32"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                <Icon.CheckLg />
                            }
                            
                            <span>Uren opslaan</span>
                        </button>
                        <span className="info">Je kunt je uren insturen via de pagina 'Uren registreren'</span>
                    </div>
                    </>
                    :
                    <h3>Er zijn nog geen uren om in te vullen</h3>
                }
            </div>
            
            <div className="blocks">
                <div className="block table-block">
                    <div className="content">
                    <div className="block-header"><h3>Jouw gegevens</h3><Icon.PersonCircle /></div>
                    <table id="info">
                        <tbody>
                        <tr>
                            <td>Naam:</td>
                            <td>{voornaam ? voornaam + ' ' + achternaam : <Skeleton height={20} />}</td>
                        </tr>
                        <tr>
                            <td>Personeelsnummer:</td>
                            <td>{personeelsnummer ? personeelsnummer : <Skeleton height={20} />}</td>
                        </tr>
                        <tr>
                            <td>Contracturen:</td>
                            <td>{contracturen ? contracturen : <Skeleton height={20} />}</td>
                        </tr>
                        <tr>
                            <td>Loonperiode:</td>
                            <td>{loonperiode ? loonperiode : <Skeleton height={20} />}</td>
                        </tr>
                        <tr>
                            <td>Divisie:</td>
                            <td>{divisie ? divisie : <Skeleton height={20} />}</td>
                        </tr>
                        <tr>
                            <td>Telefoonnummer:</td>
                            <td>{telefoonnummer ? telefoonnummer : <Skeleton height={20} />}</td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
                <div className="block table-block">
                    <div className="content">
                    <div className="block-header"><h3>Jouw registraties</h3><Icon.ClockHistory /></div>
                    {
                        allRegistraties.length === 0 ?
                        <p>Er zijn geen registraties</p>
                        :
                        <table id="info">
                            <tbody>
                                {
                                    previousWeekBefore ?
                                    <tr>
                                        <td>Week {weekNumber - 2}:</td>
                                        <td>{showStatus(previousWeekBefore.status)}</td>
                                    </tr>
                                    :
                                    null
                                }
                                {
                                    previousWeek ?
                                    <tr>
                                        <td>Week {weekNumber - 1}:</td>
                                        <td>{showStatus(previousWeek.status)}</td>
                                    </tr>
                                    :
                                    null
                                }
                                {
                                    currentWeek ?
                                    <tr>
                                        <td>Week {weekNumber}:</td>
                                        <td>{showStatus(currentWeek.status)}</td>
                                    </tr>
                                    :
                                    null
                                }
                                {
                                    nextWeek ?
                                    <tr>
                                        <td>Week {weekNumber + 1}:</td>
                                        <td>{showStatus(nextWeek.status)}</td>
                                    </tr>
                                    :
                                    null
                                }
                            </tbody>
                        </table>
                    }
                    </div>
                </div>

                {
                    medewerker && teControleren.length > 0 ?
                    <div className="block table-block">
                        <div className="content">
                        <div className="block-header"><h3>Nog te controleren</h3><Icon.ClockHistory /></div>
                        <p style={{fontFamily:'PrometoLight'}}>Het aantal nog te controleren registraties voor jou in verschillende weken:</p>
                            <table id="info">
                                <tbody>
                                {
                                    teControleren.map((item, index) =>  {
                                        return <tr>
                                            <td>Week {item.weeknummer}:</td>
                                            <td>{item.aantal}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    :
                    null
                }

                {
                    medewerker && medewerker.uren_exporteren ?
                    <div className="block table-block">
                        <div className="content">
                        <div className="block-header"><h3>Uren exporteren</h3><Icon.FileEarmarkSpreadsheet /></div>
                        <div className="export-block">
                            <h4>Uren per maand exporteren</h4>
                            <span>Bevat alleen medewerkers die per maand betaald krijgen</span>
                            <form id="month-export" onSubmit={exportMonthRegistrations}>
                                {
                                    divisies.length > 0 ?
                                    <select defaultValue={divisieExportMonth} 
                                    onChange={e => {
                                        setDivisieExportMonth(e.target.value);
                                        var index = e.nativeEvent.target.selectedIndex; 
                                        setDivisieExportMonthName(e.nativeEvent.target[index].text);
                                    }} 
                                    className="divisie">
                                        <option value="0">Alle divisies</option>
                                        {
                                            divisies.map((item, index) =>  {
                                                return <option key={index} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    :
                                    null
                                }
                                <div className="fields">
                                    <select name="months" id="months" required defaultValue={exportMonth} onChange={e => setExportMonth(Number(e.target.value))}>
                                        {
                                            months.map((item, index) =>  {
                                                return <option key={index} value={item.value}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    <input type="number" placeholder="2023" value={exportYearMonths} required onChange={(e) => setExportYearMonths(e.target.value)} />
                                </div>
                                <input type="submit" value="Exporteren" className="button" />
                                {
                                    errorMessageMonthExport ?
                                    <p className="error">{errorMessageMonthExport}</p>
                                    :
                                    null
                                }
                            </form>
                        </div>
                        <div className="export-block">
                            <h4>Uren per 4 weken exporteren</h4>
                            <span>Bevat alleen medewerkers die per 4 weken betaald krijgen</span>
                            <form id="weeks-export" onSubmit={exportWeekRegistrations}>
                                {
                                    divisies.length > 0 ?
                                    <select defaultValue={divisieExportWeeks} 
                                    onChange={e => {
                                        setDivisieExportWeeks(e.target.value);
                                        var index = e.nativeEvent.target.selectedIndex; 
                                        setDivisieExportWeeksName(e.nativeEvent.target[index].text);
                                    }} 
                                    className="divisie">
                                        <option value="0">Alle divisies</option>
                                        {
                                            divisies.map((item, index) =>  {
                                                return <option key={index} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    :
                                    null
                                }
                                <div className="fields">
                                    <input type="number" placeholder="1" value={exportFromWeek} min="1" max="52" required onChange={(e) => setExportFromWeek(Number(e.target.value))} />
                                    <input type="number" placeholder="4" value={exportToWeek} min="1" max="52" required onChange={(e) => setExportToWeek(Number(e.target.value))} />
                                    <input type="number" placeholder="2023" value={exportYearWeeks} required onChange={(e) => setExportYearWeeks(e.target.value)} />
                                </div>
                                <input type="submit" value="Exporteren" className="button" />
                                {
                                    errorMessageWeeksExport ?
                                    <p className="error">{errorMessageWeeksExport}</p>
                                    :
                                    null
                                }
                            </form>
                        </div>
                        <div className="export-block">
                            <h4>Urenlijsten downloaden</h4>
                            <span>Kies hier welke lijsten je wilt downloaden</span>
                            <form id="lists-export" onSubmit={createExcels}>
                                {
                                    divisies.length > 0 ?
                                    <select defaultValue={exportDivisieLists} 
                                    onChange={e => {
                                        setExportDivisieLists(e.target.value);
                                    }} 
                                    className="divisie">
                                        <option value="0">Alle divisies</option>
                                        {
                                            divisies.map((item, index) =>  {
                                                return <option key={index} value={item.id}>{item.name}</option>
                                            })
                                        }
                                    </select>
                                    :
                                    null
                                }
                                <div className="fields">
                                    <input type="number" placeholder="1" value={exportStartweekLists} min="1" max="52" required onChange={(e) => setExportStartweekLists(Number(e.target.value))} />
                                    <input type="number" placeholder="4" value={exportEndweekLists} min="1" max="52" required onChange={(e) => setExportEndweekLists(Number(e.target.value))} />
                                    <input type="number" placeholder="2023" value={exportYearLists} required onChange={(e) => setExportYearLists(e.target.value)} />
                                </div>
                                <input type="submit" value="Exporteren" className="button" />
                            </form>
                        </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>

            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/" />
            :
            null
        }
    <img src={vsdvArtwork} className="artwork" />    
    </div>
    );
};
export default Dashboard;